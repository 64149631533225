import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Button,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  InputAdornment,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { filterIcon, seacrhIcon, sortingIcon } from "./assets";

import BrandsController, {
  Props,
} from "./BrandsController";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
// Customizable Area End

export default class Brands extends BrandsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Brandstable = () => {
    const tabData = [
      { label: 'All Results', value: 0 },
    ];

    const brandsData = [
      {
        brandName: "Hitachi",
        industry: "Electric",
        Canname: "Lily Andros",
        Conno: 9645812485,
      },
    ];



    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography variant="h6" style={webStyle.Headingss}>
            Brands
          </Typography>
          <Button variant="contained" style={{ ...webStyle.CheckoutButton, fontFamily: "Inter", textTransform: "none", boxShadow: "none" }} onClick={this.navigateToBrandCatalogue}>
            Add New Brand
          </Button>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.NavTab }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={0} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px" }}
                    label={
                      <Typography style={webStyle.ActiveTabs}>
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Box style={webStyle.SearchContainers}>
              <InputBase
                placeholder="Search"
                style={webStyle.SearchInputs}
                inputProps={{
                  style: {
                    fontFamily:"Inter",
                    fontSize: 12,
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={seacrhIcon} alt="search icon" data-testId="Serach-image" style={{ width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
              <img src={sortingIcon} alt="sorting icon" style={{ width: 20, height: 20, marginLeft: "8px" }} />
              <IconButton>
                <img src={filterIcon} alt="filter icon" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead style={{ ...webStyle.TableHeads }}>
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, fontFamily: "Inter", borderBottom: "none" }}>
                    Brand Name
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, fontFamily: "Inter", borderBottom: "none" }}>
                    Industry / Sector
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, fontFamily: "Inter", borderBottom: "none" }}>
                    Contact Name
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, fontFamily: "Inter", borderBottom: "none" }}>
                    Contact Number
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss, borderBottom: "none" }}>
                    Actions
                  </TableCell>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={webStyle.TBodys}>
                <TableRow style={{ height: "30px" }}></TableRow>
                {this.state.brandNamesList.map((brand, index) => (
                  <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }} onClick={this.navigateToBrandCatalogue}>
                    <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                    <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0" }}>{brand.name}</TableCell>
                    <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>{brand.industry}</TableCell>
                    <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>{brand.primary_contact_name}</TableCell>
                    <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>{brand.primary_contact_number}</TableCell>
                    <TableCell align="left" style={{ borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF" }}>
                      <IconButton
                        aria-label="more"
                        data-testId="MoreVertIcon"
                        onClick={this.handleMenuClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        data-testId="MoreVertIcon"
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleMenuClose}
                        PaperProps={{
                          style: { boxShadow: 'none', border: "1px solid #E7E2Ef", width: "80px" },
                        }}
                      >
                        <MenuItem data-testId="EditMenuItem">Edit</MenuItem>
                        <MenuItem data-testId="DeleteMenuItem">Delete</MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                  <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#64748B",
                        }}
                      >
                        Showing {this.state.resultperPage * (this.state.currentPage - 1) + 1} -{" "}
                        {Math.min(
                          this.state.resultperPage * this.state.currentPage,
                          this.state.totalResults
                        )}{" "}
                        of {this.state.totalResults} results
                      </span>

                      <Pagination
                        count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                        page={this.state.currentPage}
                        onChange={this.handlePageChange}
                        siblingCount={0}
                        boundaryCount={1}
                        renderItem={(item) => (
                          <PaginationItem
                            {...item}
                            style={{
                              textDecoration: "underline",
                              borderRadius: 0,
                              background: "none",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#979797",
                            }}
                          />
                        )}
                      />
                    </div>
                  </TableCell>

                  <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                </TableRow>
                <TableRow style={{ height: "30px" }}></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Brandstable()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key: string]: {
    [property: string]: string | number;
  };
} = {
  MainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter",
  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter",
  },
  Headingss: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
  },
  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  TBodys: {
    padding: "20px",
  },
  Cells1: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
  },
  Cells: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    color: "#323842",
  },
  TableHeads: {
    backgroundColor: "#f3f4f6",
  },
  TableCellss: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  NavTab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px",
  },
  SearchContainers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "265px",
  },
  SearchInputs: {
    width: "173px",
    height: "35px",
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter",
  },
  inactiveTab: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  },
  ActiveTabs: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
};
// Customizable Area End