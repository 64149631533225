import React, { ReactNode } from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Table,
  TableContainer,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import { AddImg, downImage, FilesImg, saveImg, UploadImg } from "./assets";

import AddProductController, {
  DropdownOption,
  Props,
} from "./AddProductController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"
import { Autocomplete, Button } from "@mui/material"
// Customizable Area End

export default class AddProduct extends AddProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  /*istanbul ignore next*/
  AddProduct = () => {
    const tabData = [
      { label: 'Basic', value: 0 },
      { label: 'Feature', value: 1 },
      { label: 'Brand Details', value: 2 },
      { label: 'Standards', value: 3 },
      { label: 'Product Descriptions', value: 4 },
      { label: 'Detailed Specifications', value: 5 },
      { label: 'Images', value: 6 },
      { label: 'Documents', value: 7 },
      { label: 'Quality Tests and Certifications', value: 8 },
    ];

    const tabData1 = [
      { label: 'Brand Details', value1: 0 },
      { label: 'General Properties', value1: 1 },
      { label: 'Physical Properties', value1: 2 },
    ];


    const optionss = this.state.brandData.map((item: any) => ({
      id: item.id,
      label: item.attributes.name,
    }))

    const options = [
      {
        label: "Havells",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Polycab",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Bosch",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Phillips",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "RR Cabel",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
    ];
    const options1 = [
      {
        label: "Collection 01",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Collection 02",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Collection 03",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Collection 04",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Collection 05",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
    ];
    const optionsstandards = [
      {
        label: "IS",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "EN",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "ASTM",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
    ]

    const renderTabContent = () => {
      switch (this.state.value) {
        case 0:
          return renderBasicDetails();
        case 1:
          return renderFeatureDetails();
        case 2:
          return renderBrandDetails();
        case 3:
          return renderStandards();
        case 4:
          return renderProductDescription();
        case 5:
          return renderDetailedSpecification();
        case 6:
          return renderImages();
        case 7:
          return renderDocuments();
        default:
          return renderQualityTest();
      }
    };

    const renderTabContent1 = () => {
      switch (this.state.value1) {
        case 0:
          return renderBrandDetails1();
        case 1:
          return renderGeneralProp();
        default:
          return renderPhysicalProp();
      }
    };
    const renderBasicDetails = () => {
      return (
        <>
          <Box style={{ padding: "20px", fontFamily: "Inter" }}>
            <Box>
              <Typography style={{ ...webStyle.Title }}>
                UPID Version Data
              </Typography>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>PIM Product Identifier(UPID)</Typography>
              </Box>
              <TextField
                placeholder="Enter Product Identifier"
                onChange={(e) => this.handleChange(e, "upid")}
                value={this.state.upidName}
                inputProps={{
                  'data-test-id': 'upid-name-input',
                  style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                }}
                style={{
                  ...webStyle.formField, width: "100%", borderRadius: "8px", height: "40px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", color: "#1E293B", fontSize: "14px", },
                }}
              />
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Version Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.productVersionName}
                  placeholder="Enter Product Version Name"
                  disabled
                  onChange={(e) => this.handleChange(e, "productVersionName")}
                  inputProps={{
                    'data-testid': 'brand-name-input',
                    readOnly: true,
                    style: {
                      fontSize: "16px",
                      color: "#BCC1CA",
                      fontWeight: 400,

                    },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", color: "#1E293B", fontSize: "14px", },
                  }}
                />
                {this.renderError("productVersionName")}
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Alias</Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    borderRadius: "8px",
                    minHeight: "40px",
                    alignItems: "center",
                  }}
                >
                  {this.state.alias.map((alias: any, index: any) => (
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        background: "#E2E8F0",
                        borderRadius: "16px",
                        fontSize: "14px",
                        color: "#1E293B",
                      }}
                    >
                      {alias}
                      <span
                        onClick={() => {
                          const newAliases = this.state.alias.filter((_: any, i: any) => i !== index);
                          this.setState({ alias: newAliases });
                        }}
                        style={{
                          marginLeft: "8px",
                          cursor: "pointer",
                          color: "#DC2626",
                          fontWeight: "bold",
                        }}
                      >
                        &times;
                      </span>
                    </Box>
                  ))}
                  <TextField
                    value={this.state.inputAlias}
                    onChange={(e) => this.setState({ inputAlias: e.target.value })}
                    onKeyDown={this.handleKeyDown}
                    placeholder="Enter Alias"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        fontWeight: 400,

                        color: "#1E293B",
                      },
                    }}
                    style={{
                      ...webStyle.formField,
                      flex: 1,
                      border: "none",
                      outline: "none",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "14px",
                        padding: "6px 8px",
                        color: "#1E293B",

                        border: "none",
                        outline: "none",
                      },
                    }}

                  />
                </Box>
              </Box>

            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Category</Typography>
                </Box>
                <TextField
                  value={this.state.productCategory}
                  placeholder="Enter Product Category"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      color: "#1E293B",

                      fontSize: "14px",
                    },
                  }}
                />
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Sub Category</Typography>
                </Box>
                <TextField
                  placeholder="Enter Product Sub Category"
                  value={this.state.productSubcategory}
                  inputProps={{
                    style: { fontWeight: 400, fontSize: "16px", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",

                      fontSize: "14px",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Short Description</Typography>
              </Box>
              <TextField
                placeholder="Enter Short Description"
                value={this.state.productDescription}
                onChange={(e) => this.handleChange(e, "productDescription")}
                style={{
                  ...webStyle.formField, width: "100%", borderRadius: "8px", height: "40px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", color: "#BCC1CA", fontSize: "14px",fontWeight: 400, },
                }}
              />
            </Box>
          </Box>
          <Box style={{ padding: "20px", }}>
            <Typography style={{ ...webStyle.Title }}>
              Product Data
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Enter Product Type</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    borderRadius: "8px",
                    background: "#F3F4F6",
                    border: "1px solid #F3F4F6",
                  }}
                >
                  <select
                    value={this.state.productType}
                    onChange={(e) => this.handleChange(e, "productType")}
                    style={{
                      width: "100%",
                      padding: "6px 8px",
                      color: "#1E293B",
                      fontSize: "14px",

                      border: "none",
                      borderRadius: "8px",
                      background: "transparent",
                      height: "40px",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                    }}
                  >
                    <option value="" disabled>
                      Select Product Type
                    </option>
                    {this.state.productTypeOptions.map((option: DropdownOption) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <img
                    alt="Dropdown Icon"
                    src={downImage}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      width: "15px",
                      height: "15px",
                      transform: "translateY(-50%)",
                    }}
                  />
                  {this.renderError("productType")}
                </div>
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Physical Form</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    borderRadius: "8px",
                    background: "#F3F4F6",
                    border: "1px solid #F3F4F6",
                  }}
                >
                  <select
                    value={this.state.physicalForm}
                    onChange={(e) => this.handleChange(e, "physicalForm")}
                    style={{
                      width: "100%",
                      padding: "6px 8px",
                      color: "#1E293B",
                      fontSize: "14px",

                      border: "none",
                      borderRadius: "8px",
                      background: "transparent",
                      height: "40px",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                    }}
                  >
                    <option value="" disabled>
                      Select Physical Form
                    </option>
                    {this.state.physicalFormOptions.map((option: DropdownOption) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <img
                    alt="Dropdown Icon"
                    src={downImage}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      width: "15px",
                      height: "15px",
                      transform: "translateY(-50%)",
                    }}
                  />
                  {this.renderError("physicalForm")}
                </div>
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>
                    Material Composition
                  </Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    borderRadius: "8px",
                    minHeight: "40px",
                    alignItems: "center",
                  }}
                >
                  {this.state.materialcomp.map((material: any, index: number) => (
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        background: "#E2E8F0",
                        borderRadius: "16px",
                        fontSize: "14px",
                        color: "#1E293B",
                      }}
                    >
                      {material}
                      <span
                        onClick={() => {
                          const newMaterials = this.state.materialcomp.filter((_: any, i: any) => i !== index);
                          this.setState({ materialcomp: newMaterials });
                        }}
                        style={{
                          marginLeft: "8px",
                          cursor: "pointer",
                          color: "#DC2626",
                          fontWeight: "bold",
                        }}
                      >
                        &times;
                      </span>
                    </Box>
                  ))}
                  <TextField
                    value={this.state.inputValue}
                    onChange={(e) => this.setState({ inputValue: e.target.value })}
                    onKeyDown={this.handleKeyDown2}
                    placeholder="Enter Add Material Composition"
                    inputProps={{
                      style: {
                        fontSize: "14px",
                        fontWeight: 400,

                        color: "#1E293B",
                      },
                    }}
                    style={{
                      ...webStyle.formField,
                      flex: 1,
                      border: "none",
                      outline: "none",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "14px",
                        padding: "6px 8px",
                        color: "#1E293B",
                        border: "none",
                        outline: "none",
                      },
                    }}
                  />


                </Box>
                {this.renderError("materialcomp")}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Sample Availability</Typography>
                </Box>
                <TextField
                  value={this.state.sampleAvail}
                  onChange={(e) => this.handleChange(e, "sampleAvail")}
                  placeholder="Enter Sample Availability"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", color: "#1E293B", fontSize: "14px" },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px" }}>
            <Typography style={{ ...webStyle.Title }}>
              Product Taxation
            </Typography>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>HSN Code Number</Typography>
              </Box>
              <TextField
                value={this.state.hsncode}
                placeholder="Enter HSN Code Number"
                onChange={(e) => this.handleChange(e, "hsncode")}
                inputProps={{
                  style: { color: "#BCC1CA", fontWeight: 400, fontSize: "16px", },
                }}
                style={{
                  ...webStyle.formField, borderRadius: "8px", width: "100%", height: "40px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", color: "#1E293B", fontSize: "14px" },
                }}
              />
            </Box>
          </Box>
          <Box style={{ padding: "20px", }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Heading</Typography>
                </Box>
                <TextField
                  value={this.state.heading}
                  placeholder="Enter Heading"
                  onChange={(e) => this.handleChange(e, "heading")}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontWeight: 400,color: "#BCC1CA", },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Sub Heading</Typography>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "subheading")}
                  value={this.state.subheading}
                  placeholder="Enter Sub Heading"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontWeight: 400,
                      color: "#1E293B",
                      fontSize: "14px",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Product Description (80 letters)</Typography>
              </Box>
              <TextField
                value={this.state.productDescription1}
                placeholder="Enter Product Description"
                onChange={(e) => this.handleChange(e, "productDescription1")}
                style={{
                  ...webStyle.formField, height: "40px", width: "100%", borderRadius: "8px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: "14px", padding: "6px 8px",fontWeight: 400, color: "#BCC1CA", },
                }}
              />
            </Box>
            <Box style={webStyle.formGrid2}>
              <Box style={{ ...webStyle.formItem, flex: 1 }}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>CGST</Typography>
                </Box>
                <TextField
                  value={this.getTaxValue("cgst")}
                  onChange={(e) => this.handleTaxChange(e, "cgst")}
                  placeholder="Enter CGST"
                  style={{
                    ...webStyle.formField,
                    width: "100%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "14px",
                      padding: "6px 8px",
                      color: "#BCC1CA",
                    },
                    
                  }}
                />
              </Box>
              <Box style={{ ...webStyle.formItem, flex: 1 }}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>SGST</Typography>
                </Box>
                <TextField
                  value={this.getTaxValue("sgst")}
                  onChange={(e) => this.handleTaxChange(e, "sgst")}
                  placeholder="Enter SGST"
                  style={{
                    ...webStyle.formField,
                    width: "100%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "14px",
                      padding: "6px 8px",
                      color: "#BCC1CA",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
              <Box style={{ ...webStyle.formItem, flex: 1 }}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>IGST</Typography>
                </Box>
                <TextField
                  value={this.getTaxValue("igst")}
                  onChange={(e) => this.handleTaxChange(e, "igst")}
                  placeholder="Enter IGST"
                  style={{
                    ...webStyle.formField,
                    width: "100%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "14px",
                      padding: "6px 8px",
                      fontWeight: 400,
                      color: "#BCC1CA",

                    },
                  }}
                />

              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Typography style={{ ...webStyle.Title }}>
              Units Data
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Unit of Measurement</Typography>
                </Box>
                <TextField
                  value={this.state.unitOfMeasure}
                  onChange={(e) => this.handleChange(e, "unitOfMeasure")}
                  placeholder="Enter Unit of Measurement"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", color: "#1E293B", },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Secondary Unit of Measurement</Typography>
                </Box>
                <TextField
                  value={this.state.secondunitOfMeasure}
                  onChange={(e) => this.handleChange(e, "secondunitOfMeasure")}
                  placeholder="Enter Secondary Unit of Measurement"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",

                      fontSize: "14px",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Unit Conversion</Typography>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "unitConversion")}
                  placeholder="Enter Unit Conversion"
                  value={this.state.unitConversion}
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      color: "#1E293B",

                      fontSize: "14px",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box onClick={this.handleSubmit} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImga" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderProductDescription = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formItem}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Product Description</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
              </Box>
              <TextField
                value={this.state.productDescript}
                placeholder="Enter Product Description"
                onChange={(e) => this.handleChange(e, "productDescript")}
                inputProps={{
                  style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                }} style={{
                  ...webStyle.formField1,
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: "6px 8px",

                    fontSize: "14px",
                    color: "#1E293B",
                  },
                }}
              />
              {this.state.errors.productDescript && (
                <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "4px" }}>
                  {this.state.errors.productDescript}
                </Typography>
              )}
            </Box>

          </Box>
          <Box onClick={this.handleSubmits1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderDetailedSpecification = () => {
      return (
        <>
          <Box style={{ width: "94%", ...webStyle.MainContainer }}>
            <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
              <Box style={{ ...webStyle.navTabContainer }}>
                <Box
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    overflowX: "auto",
                  }}
                >
                  <Tabs value={this.state.value1} onChange={(event, newValue) => this.handleTabChange1(newValue)} TabIndicatorProps={{
                    style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                  }}>
                    {tabData1.map(({ label, value1 }) => (
                      <Tab
                        key={value1}
                        style={{ textTransform: "none", height: "58px" }}
                        label={
                          <Typography
                            style={this.state.value1 === value1 ? webStyle.activeTab : webStyle.inactiveTab}
                          >
                            {label}
                          </Typography>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
              <TableContainer>
                <Table aria-label="simple table">
                  {renderTabContent1()}
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box onClick={() => { this.setState({ value: 6 }); this.detailSpecificationApi(); }} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderStandards = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGridstand}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Standards</Typography><span style={{ fontWeight: 700, color: "#DC2626", marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  disablePortal
                  options={optionsstandards}
                  id="catalogue-field"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={""}
                      placeholder="Select Standards"
                      error={Boolean(this.state.errors?.standards)}
                      helperText={this.state.errors?.standards}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "14px",

                          color: "#1E293B",
                          padding: "6px 8px",
                          background: "#F3F4F6",
                          borderRadius: "8px",
                          height: "40px",
                        },
                        endAdornment: (
                          <img
                            alt="Dropdown Icon"
                            style={{ width: "15px", marginRight: "8px", height: "15px", }}
                            src={downImage}
                          />
                        ),
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontWeight: 400,
                          color: "#BCC1CA",
                          fontStyle: "Inter  ",
                          fontSize: "16px",
                        },
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      border: "1px solid #F3F4F6",
                      maxHeight: "200px",
                      borderRadius: "8px",
                      boxShadow: "none",
                    },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>IS Number</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "IsNumber")}
                  placeholder="Enter IS Number"
                  error={Boolean(this.state.errors.IsNumber)}
                  helperText={this.state.errors.IsNumber}
                  value={this.state.IsNumber}
                  inputProps={{
                    style: { fontWeight: 400, fontSize: "16px", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      color: "#1E293B",

                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box onClick={() => { this.setState({ value: 4 }); this.standardsApi(); }} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderFeatureDetails = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Typography style={{ ...webStyle.Title }}>
              Brand Details
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand</Typography>
                </Box>
                <TextField
                  value={this.state.brands}
                  onChange={(e) => this.handleChange(e, "brands")}
                  placeholder="Enter Brand"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",

                      fontSize: "14px",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Collection Name</Typography>
                </Box>
                <TextField
                  value={this.state.brandCollectName}
                  onChange={(e) => this.handleChange(e, "brandCollectName")}
                  placeholder="Enter Brand Collection Name"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",

                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Standard Packaging</Typography>
                </Box>
                <TextField
                  value={this.state.standardPackage}
                  placeholder="Enter Standard Packaging"
                  onChange={(e) => this.handleChange(e, "standardPackage")}
                  inputProps={{
                    style: { fontWeight: 400, color: "#BCC1CA", fontSize: "16px", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", color: "#1E293B", },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Master Packaging</Typography>
                </Box>
                <TextField
                  placeholder="Enter Master Packaging"
                  value={this.state.masterPackage}
                  onChange={(e) => this.handleChange(e, "masterPackage")}
                  inputProps={{
                    style: { fontSize: "16px", color: "#BCC1CA", fontWeight: 400, },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: "14px", padding: "6px 8px", color: "#1E293B", },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Typography style={{ ...webStyle.Title }}>
              General Properties
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Colour</Typography>
                </Box>
                <TextField
                  value={this.state.colour}
                  onChange={(e) => this.handleChange(e, "colour")}
                  placeholder="Enter Colour"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",

                      fontSize: "14px",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Material</Typography>
                </Box>
                <TextField
                  value={this.state.material}
                  onChange={(e) => this.handleChange(e, "material")}
                  placeholder="Enter Material"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",

                      fontSize: "14px",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Typography style={{ ...webStyle.Title }}>
              Physical Properties
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Length in Inch</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <TextField
                  value={this.state.lengthInInch}
                  onChange={(e) => this.handleChange(e, "lengthInInch")}
                  placeholder="Enter Length in Inch"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA" },
                  }}
                  style={{ ...webStyle.formField }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", color: "#1E293B" },
                  }}
                  error={!!this.state.errors.lengthInInch}
                  helperText={this.state.errors.lengthInInch}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Width in Inch</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <TextField
                  placeholder="Enter Width in Inch"
                  value={this.state.widthInInch}
                  onChange={(e) => this.handleChange(e, "widthInInch")}
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA" },
                  }}
                  style={{ ...webStyle.formField }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", color: "#1E293B", fontSize: "14px" },
                  }}
                  error={!!this.state.errors.widthInInch}
                  helperText={this.state.errors.widthInInch}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Inlet Size</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <TextField
                  value={this.state.InletSize}
                  placeholder="Enter Inlet Size"
                  onChange={(e) => this.handleChange(e, "InletSize")}
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA" },
                  }}
                  style={{ ...webStyle.formField }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", color: "#1E293B", },
                  }}
                  error={!!this.state.errors.InletSize}
                  helperText={this.state.errors.InletSize}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Thickness</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <TextField
                  value={this.state.thickness}
                  placeholder="Enter Thickness"
                  onChange={(e) => this.handleChange(e, "thickness")}
                  inputProps={{
                    style: { color: "#BCC1CA", fontSize: "16px", fontWeight: 400, },
                  }}
                  style={{ ...webStyle.formField }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", color: "#1E293B", fontSize: "14px" },
                  }}
                  error={!!this.state.errors.thickness}
                  helperText={this.state.errors.thickness}
                />
              </Box>
            </Box>
          </Box>
          <Box onClick={this.handleSubmit1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img data-testid="save-button" src={saveImg} alt="saveImg1" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderBrandDetails1 = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid4}>
              {Array.from({ length: 8 }).map((_, index) => (
                <React.Fragment key={index}>
                  <Box style={webStyle.formItem}>
                    {index === 0 && (
                      <Box style={{ display: "flex" }}>
                        <Typography style={webStyle.label}>Feature</Typography>
                      </Box>
                    )}
                    {index !== 0 && (
                      <Box style={{ display: "flex" }}>
                        <Typography style={webStyle.label}><br /></Typography>
                      </Box>
                    )}
                    <TextField
                      onChange={(e) => this.handleChange(e, "feature")}
                      value={this.state.feature}
                      placeholder=""
                      style={{
                        ...webStyle.formField,
                        width: "100%",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#BCC1CA",
                          padding: "6px 8px",
                        },
                      }}
                    />
                  </Box>

                  <Box style={webStyle.formItem}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Key</Typography>
                    </Box>
                    <TextField
                      value={this.state.keys[index]}
                      placeholder="Enter Key"
                      onChange={(e) => this.handleKeyChange(e, index, "keys")}
                      style={{
                        ...webStyle.formField,
                        width: "100%",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          padding: "6px 8px",
                          fontWeight: 400,
                          color: "#BCC1CA",
                          fontSize: "16px",
                        },
                      }}
                    />
                  </Box>
                  <Box style={webStyle.formItem}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Value</Typography>
                    </Box>
                    <Autocomplete
                      disablePortal
                      options={options}
                      id="catalogue-field"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Value"
                          value=""
                          inputProps={{
                            disableUnderline: true,
                            ...params.inputProps,
                            style: {
                              fontSize: "16px",
                              color: "#BCC1CA",
                              fontWeight: 400,
                              fontStyle: "Inter",
                              height: "40px",
                              background: "#F3F4F6",
                              borderRadius: "8px",
                              padding: "6px 8px",
                            },
                            endAdornment: (
                              <img
                                alt="Dropdown Icon"
                                src={downImage}
                                style={{ width: "12px", height: "7px", marginRight: "8px" }}
                              />
                            ),
                          }}
                        />
                      )}
                      sx={{
                        "& .MuiAutocomplete-paper": {
                          boxShadow: "none",
                          border: "1px solid #F3F4F6",
                          maxHeight: "200px",
                          borderRadius: "8px",
                        },
                      }}
                    />
                  </Box>
                </React.Fragment>
              ))}
            </Box>

          </Box>
        </>
      );
    };

    const renderGeneralProp = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid4}>
              {Array.from({ length: 10 }).map((_, index) => (
                <React.Fragment key={index}>
                  <Box style={webStyle.formItem}>
                    {index !== 0 && (
                      <Box style={{ display: "flex" }}>
                        <Typography style={webStyle.label}><br /></Typography>
                      </Box>
                    )}
                    {index === 0 && (
                      <Box style={{ display: "flex" }}>
                        <Typography style={webStyle.label}>Feature</Typography>
                      </Box>
                    )}
                    <TextField
                      placeholder=""
                      onChange={(e) => this.handleChange(e, "feature")}
                      value={this.state.feature}
                      style={{
                        ...webStyle.formField,
                        width: "100%",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          padding: "6px 8px",
                          color: "#BCC1CA",
                          fontSize: "16px",
                          fontWeight: 400,
                        },
                      }}
                    />
                  </Box>

                  <Box style={webStyle.formItem}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Key</Typography>
                    </Box>
                    <TextField
                      placeholder="Enter Key"
                      value={this.state.keys1[index]}
                      onChange={(e) => this.handleKeyChange(e, index, "keys1")}
                      inputProps={{
                        style: {
                         

                        },
                      }}
                      style={{
                        ...webStyle.formField,
                        width: "100%",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          padding: "6px 8px",
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#BCC1CA",
                        },
                      }}
                    />
                  </Box>
                  <Box style={webStyle.formItem}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Value</Typography>
                    </Box>
                    <Autocomplete
                      disablePortal
                      options={options}
                      id="catalogue-field"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Value"
                          value=""
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              fontWeight: 400,
                              fontSize: "16px",
                              fontStyle: "Inter  ",
                              color: "#BCC1CA",
                              padding: "6px 8px",
                              height: "40px",
                              borderRadius: "8px",
                              background: "#F3F4F6",
                            },
                            endAdornment: (
                              <img
                                src={downImage}
                                alt="Dropdown Icon"
                                style={{ width: "12px", height: "7px", marginRight: "8px" }}
                              />
                            ),
                          }}
                        />
                      )}
                      sx={{
                        "& .MuiAutocomplete-paper": {
                          maxHeight: "200px",
                          borderRadius: "8px",
                          boxShadow: "none",
                          border: "1px solid #F3F4F6",
                        },
                      }}
                    />
                  </Box>
                </React.Fragment>
              ))}
            </Box>

          </Box>
        </>
      );
    };

    const renderPhysicalProp = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid5}>
              {Array.from({ length: 15 }).map((_, index) => (
                <React.Fragment key={index}>
                  <Box style={webStyle.formItem}>
                    {index === 0 && (
                      <Box style={{ display: "flex" }}>
                        <Typography style={webStyle.label}>Feature</Typography>
                      </Box>
                    )}
                    {index !== 0 && (
                      <Box style={{ display: "flex" }}>
                        <Typography style={webStyle.label}><br></br></Typography>
                      </Box>
                    )}
                    <TextField
                      value={this.state.feature}
                      placeholder=""
                      onChange={(e) => this.handleChange(e, "feature")}
                      style={{
                        ...webStyle.formField,
                        width: "100%",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#BCC1CA",
                          padding: "6px 8px",
                        },
                      }}
                    />
                  </Box>

                  <Box style={webStyle.formItem}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Key</Typography>
                    </Box>
                    <TextField
                      onChange={(e) => this.handleKeyChange(e, index, "keys2")}
                      value={this.state.keys2[index]}
                      placeholder="Enter Key"
                    
                      style={{
                        ...webStyle.formField,
                        width: "100%",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          color: "#BCC1CA",
                          fontWeight: 400,
                          fontSize: "16px",
                          padding: "6px 8px",
                        },
                      }}
                    />
                  </Box>
                  <Box style={webStyle.formItem}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Unit</Typography>
                    </Box>
                    <TextField
                      value={this.state.units[index]}
                      placeholder="Enter Units"
                      onChange={(e) => this.handleKeyChange(e, index, "units")}
                      style={{
                        ...webStyle.formField,
                        width: "100%",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          padding: "6px 8px",
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#BCC1CA",
                        },
                      }}
                    />
                  </Box>
                  <Box style={webStyle.formItem}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Value</Typography>
                    </Box>
                    <Autocomplete
                      disablePortal
                      id="catalogue-field"
                      options={options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Value"
                          value=""
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              fontSize: "16px",
                              fontWeight: 400,
                              fontStyle: "Inter  ",
                              color: "#BCC1CA",
                              padding: "6px 8px",
                              height: "40px",
                              background: "#F3F4F6",
                              borderRadius: "8px",
                            },
                            endAdornment: (
                              <img
                                alt="Dropdown Icon"
                                src={downImage}
                                style={{ width: "12px", height: "7px", marginRight: "8px" }}
                              />
                            ),
                          }}
                        />
                      )}
                      sx={{
                        "& .MuiAutocomplete-paper": {
                          boxShadow: "none",
                          border: "1px solid #F3F4F6",
                          borderRadius: "8px",
                          maxHeight: "200px",
                        },
                      }}
                    />
                  </Box>
                </React.Fragment>
              ))}
            </Box>

          </Box>
        </>
      );
    };
    const renderQualityTest = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Certificate Name</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  placeholder="Enter Certificate Name"
                  value={this.state.certificateName}
                  onChange={(e) => this.handleChange(e, "certificateName")}
                  style={{
                    ...webStyle.formField, width: "100%", height: "40px", borderRadius: "8px",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "8px 8px", fontSize: "16px", color: "#BCC1CA", fontWeight: 400,},
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Quality Test Name</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.qualityTestName}
                  onChange={(e) => this.handleChange(e, "qualityTestName")}
                  placeholder="Enter Quality Test Name"
                  style={webStyle.formField}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "16px", 
                      fontWeight: 400, 
                      color: "#BCC1CA"
                    },
                  }}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              {/* First file input */}
              <Box>
                <Box
                  style={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#F3F4F6",
                    height: "256px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="file"
                    id="quality-file-1"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => this.handleFileChange(e, "qualityFile1")}
                  />
                  {this.state.qualityFile1Name && (
                    <Typography
                      style={{

                        fontSize: "14px",
                        fontWeight: 400,
                        marginLeft: "10px",
                        color: "blue",
                        paddingBottom: "10px",
                      }}
                    >
                      {this.state.qualityFile1Name}
                    </Typography>
                  )}

                  <label htmlFor="quality-file-1">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label><br />
                  <Typography style={{ marginTop: "3px", fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                  <Box style={{ display: "flex", marginLeft: "7px" }}>
                    <Typography component="span" style={{
                      textDecoration: "underline", cursor: "pointer",
                      fontSize: "14px", color: "#FF6600", fontWeight: 400, marginTop: "8px"
                    }}>
                      Browse Files
                    </Typography>
                    <img src={FilesImg} alt="FilesImg" style={{ width: "18px", margin: "7px 8px", height: "18px" }} />
                  </Box>
                </Box>
              </Box>

              {/* Second file input */}
              <Box
                style={{
                  height: "256px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  background: "#F3F4F6",
                  justifyContent: "center",
                }}
              >
                <input
                  type="file"
                  id="quality-file-2"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => this.handleFileChange(e, "qualityTest1")}
                />
                {this.state.qualityTest1Name && (
                  <Typography
                    style={{

                      fontSize: "14px",
                      fontWeight: 400,
                      marginLeft: "10px",
                      color: "blue",
                      paddingBottom: "10px",
                    }}
                  >
                    {this.state.qualityTest1Name}
                  </Typography>
                )}
                <label htmlFor="quality-file-2">
                  <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                </label><br />
                <Typography style={{ marginTop: "3px", fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                <Box style={{ display: "flex", marginLeft: "7px" }}>
                  <Typography component="span" style={{
                    textDecoration: "underline", cursor: "pointer", color: "#FF6600",
                    fontSize: "14px", fontWeight: 400, marginTop: "8px"
                  }}>
                    Browse Files
                  </Typography>
                  <img src={FilesImg} alt="FilesImg" style={{ width: "18px", height: "18px", margin: "7px 8px" }} />
                </Box>
              </Box>
            </Box>

            {/* Add another Certificate and Quality Test sections */}
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <input
                  type="file"
                  id="certificate-upload"
                  style={{ display: "none" }}
                  accept="application/pdf,image/*"
                />
                <label htmlFor="certificate-upload" style={{ display: "flex", cursor: "pointer" }}>
                  <img alt="AddImg" src={AddImg} />
                  <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>
                    Add another Certificate
                  </Typography>
                </label>
              </Box>

              <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <input
                  type="file"
                  id="quality-test-upload"
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <label htmlFor="quality-test-upload" style={{ display: "flex", cursor: "pointer" }}>
                  <img src={AddImg} alt="AddImg" />
                  <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>
                    Add another Quality Test
                  </Typography>
                </label>
              </Box>
            </Box>
          </Box>

          <Box onClick={this.certificationApi} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };


    const renderDocuments = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Document Title</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  placeholder="Enter Document Title"
                  value={this.state.docTitle}
                  onChange={(e) => this.handleChange(e, "docTitle")}
                   style={{
                    ...webStyle.formField, width: "100%", borderRadius: "8px", height: "40px",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {padding: "8px 8px",fontSize: "16px", fontWeight: 400, color: "#BCC1CA" },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Document Type</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  disablePortal
                  id="catalogue-field"
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Document Type"
                      value=""
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontWeight: 400,
                          fontStyle: "Inter  ",
                          color: "#BCC1CA",
                          fontSize: "16px",
                          background: "#F3F4F6",
                          padding: "6px 8px",
                          height: "40px",
                          borderRadius: "8px",
                        },
                        endAdornment: (
                          <img
                            alt="Dropdown Icon"
                            style={{ width: "12px", height: "7px", marginRight: "8px" }}
                            src={downImage}
                          />
                        ),
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      border: "1px solid #F3F4F6",
                      boxShadow: "none",
                      maxHeight: "200px",
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Upload Document</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Box
                  style={{
                    borderRadius: "8px",
                    height: "256px",
                    display: "flex",
                    flexDirection: "column",
                    background: "#F3F4F6",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => this.handleFileChange(e, "documentFile")}
                  />
                  {this.state.documentFileName && (
                    <Typography
                      style={{

                        fontSize: "14px",
                        fontWeight: 400,
                        marginLeft: "10px",
                        color: "blue",
                        paddingBottom: "10px",
                      }}
                    >
                      {this.state.documentFileName}
                    </Typography>
                  )}
                  <label htmlFor="upload-file">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label><br />
                  <Typography style={{ marginTop: "3px", fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                  <Box style={{ display: "flex", marginLeft: "7px" }}>
                    <Typography component="span" style={{
                      textDecoration: "underline", cursor: "pointer", color: "#FF6600",
                      fontSize: "14px", marginTop: "8px", fontWeight: 400,
                    }}>
                      Browse Files
                    </Typography>
                    <img src={FilesImg} alt="FilesImg" style={{ width: "18px", height: "18px", margin: "7px 8px" }} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <input
                  type="file"
                  id="quality-test-upload"
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <label htmlFor="quality-test-upload" style={{ display: "flex", cursor: "pointer" }}>
                  <img src={AddImg} alt="AddImg" />
                  <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>
                    Add another Document
                  </Typography>
                </label>
              </Box>
            </Box>
          </Box>
          <Box onClick={() => { this.setState({ value: 8 }); this.documentsApi(); }} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderImages = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Upload Product Images</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Box
                  style={{
                    height: "256px",
                    borderRadius: "8px",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    background: "#F3F4F6",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => this.handleFileChange(e, "pictureFile")}
                  />
                  {this.state.pictureFileName && (
                    <Typography
                      style={{

                        fontSize: "14px",
                        fontWeight: 400,
                        marginLeft: "10px",
                        color: "blue",
                        paddingBottom: "10px",
                      }}
                    >
                      {this.state.pictureFileName}
                    </Typography>
                  )}
                  <label htmlFor="upload-file">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label><br />
                  <Typography style={{ marginTop: "3px", fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                  <Box style={{ display: "flex", marginLeft: "7px" }}>
                    <Typography component="span" style={{
                      textDecoration: "underline", color: "#FF6600",
                      fontSize: "14px", fontWeight: 400, marginTop: "8px", cursor: "pointer"
                    }}>
                      Browse Files
                    </Typography>
                    <img src={FilesImg} alt="FilesImg" style={{ width: "18px", height: "18px", margin: "7px 8px" }} />
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  width: "220px",
                  height: "256px",
                  border: '2px dashed #FF6600',
                  marginTop: "35px",
                  marginLeft: "10px",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  id="add-file"
                  type="file"
                  style={{ display: "none" }}
                />
                <label htmlFor="add-file">
                  <img src={AddImg} alt="Add" style={{ cursor: "pointer" }} />
                </label>
              </Box>
            </Box>
          </Box>
          <Box onClick={() => { this.setState({ value: 7 }); this.imagesApi(); }} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderBrandDetails = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brands</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  disablePortal
                  id="catalogue-field"
                  options={optionss}
                  getOptionLabel={(option) => option.label}
                  onChange={this.handleBrandChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value=""
                      placeholder="Select Brands"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          height: "40px",
                          fontWeight: 400,
                          fontStyle: "Inter  ",
                          fontSize: "16px",
                          color: "#BCC1CA",
                          background: "#F3F4F6",
                          padding: "6px 8px",
                          borderRadius: "8px",
                        },
                        endAdornment: (
                          <img
                            alt="Dropdown Icon"
                            style={{ width: "15px", height: "15px", marginRight: "8px" }}
                            src={downImage}
                          />
                        ),
                      }}

                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      maxHeight: "200px",
                      borderRadius: "8px",
                      boxShadow: "none",
                      border: "1px solid #F3F4F6",
                    },
                  }}
                />
                {this.state.errors?.selectedBrand && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.selectedBrand}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Collection Name</Typography>
                </Box>
                <Autocomplete
                  disablePortal
                  options={options1}
                  id="catalogue-field"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value=""
                      placeholder="Brand Collection Name"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#BCC1CA",
                          fontStyle: "Inter  ",
                          borderRadius: "8px",
                          padding: "6px 8px",
                          height: "40px",
                          background: "#F3F4F6",
                        },
                        endAdornment: (
                          <img
                            style={{ width: "15px", height: "15px", marginRight: "8px" }}
                            alt="Dropdown Icon"
                            src={downImage}
                          />
                        ),
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      maxHeight: "200px",
                      borderRadius: "8px",
                      boxShadow: "none",
                      border: "1px solid #F3F4F6",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Product Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.brandProduct}
                  onChange={(e) => this.handleChange(e, "brandProduct")}
                  placeholder="Enter Brand Product Name"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "16px",
                       fontWeight: 400, 
                       color: "#BCC1CA" 
                    },
                  }}
                />
                {this.state.errors?.brandProduct && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.brandProduct}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand SKU Number</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.brandSKU}
                  onChange={(e) => this.handleChange(e, "brandSKU")}
                  placeholder="Enter Brand SKU Number"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#BCC1CA"
                    },
                  }}
                />
                {this.state.errors?.brandSKU && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.brandSKU}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Standard Packaging</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.standardPackage1}
                  onChange={(e) => this.handleChange(e, "standardPackage1")}
                  placeholder="Enter Standard Packaging"
                    style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", 
                      fontSize: "16px",
                      fontWeight: 400,
                       color: "#BCC1CA", },
                  }}
                />
                {this.state.errors?.standardPackage1 && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.standardPackage1}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Master Packaging</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.masterPackage1}
                  onChange={(e) => this.handleChange(e, "masterPackage1")}
                  placeholder="Enter Master Packaging"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px",fontWeight: 400, fontSize: "16px", color: "#BCC1CA" },
                  }}
                />
                {this.state.errors?.masterPackage1 && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.masterPackage1}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box onClick={this.handleSubmits} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography variant="h6" style={webStyle.Headingss}>
            Add Product
          </Typography>
          <Button variant="contained" style={{ ...webStyle.CheckoutButton, textTransform: "none", boxShadow: "none" }}>
            Bulk Upload (CSV)
          </Button>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px", minWidth: `${label.length * 7 + 30}px`, }}
                    label={
                      <Typography
                        style={this.state.value === value ? webStyle.activeTab : webStyle.inactiveTab}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              {renderTabContent()}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render(): ReactNode {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.AddProduct()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainContainer: {
    marginLeft: "30px",
    fontFamily: 'Inter',
    marginTop: "20px",
  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
  },
  Headingss: {
    fontFamily: 'Inter',
    lineHeight: "1.5",
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "left" as React.CSSProperties["textAlign"]
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "start",
    gap: "20px",
  },
  formGridstand: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "start",
    gap: "20px",
    paddingBottom: '25%'
  },
  formGrid2: {
    display: "grid",
    gap: "15px",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "start",

  },
  formGrid3: {
    display: "grid",
    alignItems: "start",
    gridTemplateColumns: "3fr 3fr 1fr 3fr 3fr",
    gap: "16px",

  },
  formGrid4: {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: "1fr 8fr 8fr",
    alignItems: "start",
  },
  formGrid5: {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: "1fr 5fr 5fr 5fr",
    alignItems: "start",
  },

  formItem: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',
    width: '100%' as string,

  },
  AddType: {
    fontSize: "16px",
    marginLeft: "8px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FF6600",
  },
  formField: {
    background: '#F3F4F6',
    borderRadius: '8px',
    fontFamily: 'Inter',
    width: '100%',
    height: '40px',
    boxSizing: 'border-box' as 'border-box',
  } as React.CSSProperties,
  formField1: {
    background: '#F3F4F6',
    width: '100%',
    height: '200px',
    borderRadius: '8px',
    boxSizing: 'border-box' as 'border-box',
  } as React.CSSProperties,
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    marginTop: "10px",
    fontFamily:'Inter',
    fontWeight: 700,
    color: "#334155",
  },
  textFieldStyle: {
    fontWeight: 400,
    backgroundColor: "#F3F4F6",
    fontSize: "14px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "none",

  },
  uploadContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },

  saveButton: {
    boxShadow: "none",
    margin: "16px 20px 16px 0px",
    fontFamily: 'Inter',
    cursor: 'grab',
  },

  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    width: "max-content",
    fontWeight: 500,
    height: "44px",
    cursor: 'grab',
  },
  navTabContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "58px",
    justifyContent: "space-around",
    borderBottom: "1px solid #E1E7EF",

  },
  activeTab: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '12px',
    textAlign: 'center' as 'center',
    lineHeight: '24px',
    color: '#EE6900',
    cursor: 'grab',
  },
  inactiveTab: {
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'left' as 'left',
    fontFamily: 'Inter',
    color: '#565E6C',
    cursor: 'grab',
  },
  Title: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: "20px",
    fontFamily: 'Inter',
    marginTop: "20px"
  }
}
// Customizable Area End