import React from "react";


import {
  // Customizable Area Start
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TableCell,
  MenuItem,
  InputAdornment,
  Select,
  TableFooter,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar.web"
import Navbar from "../../../components/src/Navbar.web";
import ProductVariationController, { Props } from "./ProductVariationController";
import SearchIcon from "@material-ui/icons/Search"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/DeleteOutline";



// Customizable Area End

export default class ProductVariation extends ProductVariationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  cartTable = () => {
    const { showVariations, showVariations1 } = this.state;
    const dummyData = [
      { id: 1, length: "10mm", width: "20mm", height: "10mm", version: "UPID 1, Version 1" },
      { id: 2, length: "10mm", width: "30mm", height: "10mm", version: "UPID 1, Version 2" },
      { id: 3, length: "20mm", width: "20mm", height: "10mm", version: "UPID 1, Version 3" },
      { id: 4, length: "20mm", width: "30mm", height: "10mm", version: "UPID 1, Version 4" },
    ];

    return (
      <Box style={{ width: "94%", ...webStyle.mainContainer }}>

        <Box style={webStyle.header}>
          <Typography variant="h6" style={webStyle.heading}>
            Add Product Variation
          </Typography>
          {this.state.showVariations && (
            <Button
              variant="contained"
              style={{
                ...webStyle.SaveButton,
                textTransform: "none",
                boxShadow: "none",
              }}
              onClick={this.handleSave}
            >
              Save
            </Button>
          )}
          {this.state.showEdit && (
            <Button
              variant="contained"
              style={{
                ...webStyle.EditButton,
                textTransform: "none",
                boxShadow: "none",
              }}

            >
              Edit
            </Button>
          )}
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <TableContainer>
            <Table aria-label="Industry Market Table" style={{ overflow: 'hidden', height: '340px' }}>
              <TableBody style={webStyle.Tbody}>
                <Box style={{ ...webStyle.formItem }}>
                  <Box style={{ display: "flex" }}>
                    <Typography style={webStyle.label}>UPID Name</Typography>
                    <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                  </Box>
                  <TextField
                    placeholder="Search UPID Name"
                    inputProps={{
                      style: {
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Inter",
                        color: "#BCC1CA",
                      },
                    }}
                    style={{
                      ...webStyle.formField,
                      width: "96%",
                      height: "40px",
                      borderRadius: "8px",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        padding: "6px 8px",
                        fontSize: "14px",
                        fontFamily: "Inter",
                        color: "#1E293B",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon style={{ color: "#BCC1CA", cursor: "pointer" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={webStyle.formGrid}>

                  {this.state.fields.map((field: any, index: number) => (
                    <Box style={webStyle.formItems} key={index}>
                      <Box style={{ width: "46%" }}>
                        <Typography style={webStyle.label}>Product Criteria <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                        </Typography>
                        <Select

                          value={field.productCriteria || ""}
                          onChange={(e) => this.handleCriteriaChange(index, e)}
                          displayEmpty
                          inputProps={{
                            "data-testid": `product-criteria-select-${index}`,
                            placeholder: "Select Product Criteria",
                            style: {

                              fontWeight: 400,
                              fontSize: "16px",
                              fontFamily: "Inter",
                              color: field.productCriteria ? "#1E293B" : "#BCC1CA",
                            },
                          }}
                          style={{
                            ...webStyle.formField,
                            width: "100%",
                            borderRadius: "8px",

                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                border: "1px solid #E0E0E0",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          disableUnderline
                        >
                          <MenuItem value="Criteria 1">Height</MenuItem>
                          <MenuItem value="Criteria 2">Weight</MenuItem>
                          <MenuItem value="Criteria 3">Length</MenuItem>
                        </Select>
                      </Box>
                      <Box style={{ width: "46%" }}>
                        <Typography style={webStyle.label}>Values
                        </Typography>
                        <TextField
                          placeholder="Enter Values"
                          value={field.value}
                          inputProps={{
                            "data-testid": `brand-name-input-${index}`,
                            style: {
                              fontWeight: 400,
                              fontSize: "16px",
                              fontFamily: "Inter",
                              color: "#BCC1CA",
                            },
                          }}
                          style={{
                            ...webStyle.formField,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B" },
                          }}
                          onChange={(e) => this.handleFieldChange(index, e.target.value)}
                        />


                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        {this.state.fields.length > 1 && (
                          <IconButton onClick={() => this.removeField(index)} >
                            {!this.state.showEdit && <DeleteIcon style={{ color: '#DC2626' }} />}

                          </IconButton>
                        )}
                      </Box>

                    </Box>

                  ))}
                </Box>
                {((this.state.fields.length < 3) && !this.state.showVariations) && (
                  <Box style={{ display: "flex", marginLeft: "17px", marginTop: "16px" }}>
                    <Button
                      style={{
                        textTransform: "none",
                        color: "#F97316",
                        fontWeight: 600,
                        fontSize: "14px",
                        fontFamily: "Inter",
                      }}
                      onClick={this.addField}
                    >
                      <AddCircleOutlineIcon style={{ marginRight: "8px" }} />
                      Add another Variation
                    </Button>
                  </Box>
                )}

                {showVariations1 && (
                  <Box style={{ fontFamily: "Inter", }}>
                    <Typography variant="h6" style={{ marginLeft: "20px", fontWeight: "bold", fontFamily: "Inter" }}>
                      Product Variations
                    </Typography>
                    <TableContainer style={{ border: "1px solid #E2E7F0", borderBottom: "none", borderRadius: "8px", width: "96%", marginLeft: "20px", fontFamily: "Inter" }}>
                      <Table style={{}}>
                        <TableRow style={webStyle.tableHeaderRow}>
                          <TableCell>#</TableCell>
                          <TableCell>Length</TableCell>
                          <TableCell>Width</TableCell>
                          <TableCell>Height</TableCell>
                          <TableCell>Attach UPID Version</TableCell>
                        </TableRow>
                        <TableBody>
                          {dummyData.map((row) => (
                            <TableRow
                              key={row.id}
                              style={{
                                borderBottom: "none",
                                border: "1px solid #E2E7F0",
                                fontFamily: "Inter",
                                marginLeft: "10px"

                              }}
                            >
                              <TableCell >{row.id}</TableCell>
                              <TableCell>{row.length}</TableCell>
                              <TableCell>{row.width}</TableCell>
                              <TableCell>{row.height}</TableCell>
                              <TableCell>
                                <Select
                                  value={row.version}
                                  displayEmpty
                                  inputProps={{
                                    "data-testid": `product-criteria-select`,
                                    style: {
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      fontFamily: "Inter",
                                      backgroundColor: "transparent",
                                      marginLeft: "10px",
                                    },
                                  }}
                                  style={{
                                    width: "100%",
                                    borderRadius: "8px",
                                    backgroundColor: "#F3F4F6",
                                    border: "none",
                                    outline: "none",
                                    boxShadow: "none",
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                        border: "1px solid #E0E0E0",
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "8px",
                                        marginLeft:"10px"
                                      },
                                    },
                                  }}
                                  disableUnderline
                                >
                                  {dummyData.map((item) => (
                                    <MenuItem
                                      key={item.id}
                                      value={item.version}
                                      style={{
                                        marginLeft: "10px", // Add left margin here
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        fontFamily: "Inter",
                                      }}
                                    >
                                      {item.version}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </TableCell>


                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={3} align="right">
                    {!showVariations && (
                      <Box style={{ display: "flex", justifyContent: "flex-end", margin: "16px" }}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#F97316",
                            color: "#fff",
                            fontWeight: 600,
                            fontSize: "14px",
                            fontFamily: "Inter",
                            padding: "8px 16px",
                            borderRadius: "8px",
                          }}
                          onClick={this.handleGenerateVariations}
                        >
                          Generate Variations
                        </Button>
                      </Box>
                    )}

                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  };

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigate} />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.cartTable()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key: string]: {
    [property: string]: string | number
  }
} = {
  mainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter",

  },
  formGrid: {

    width: "96%",
  },
  formItem: {

    width: '100%' as string,
    marginTop: "20px",
    marginLeft: '20px',
    marginBottom: '15px'
  },
  tableHeaderRow: {
    backgroundColor: "#F3F4F6",
    fontWeight: "bold",
    color: "#334155",
  },
  formItems: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%' as string,
    marginTop: "20px",
    marginLeft: '20px',
    marginBottom: '15px'
  },
  heading: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",

  },
  Tbody: {
    padding: "20px",
  },
  SaveButton: {
    backgroundColor: "#ff6600",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  EditButton: {
    backgroundColor: "#BDC1CA",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "40px",
    width: "max-content",
  },
  TableCell: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  tableBody: {
    width: "1045px",
    padding: "15px",
  },
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334155",
    fontFamily: "Inter",
  },
  formField: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    background: '#F3F4F6',
    boxSizing: 'border-box' as 'border-box',
  },



};

// Customizable Area End