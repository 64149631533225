import React from "react";
import {
    // Customizable Area Start
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Dialog,
    DialogContent,
    styled,
    TextField,
    TableCell,
    IconButton,
    Menu,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar.web"
import Navbar from "../../../components/src/Navbar.web";
import ApplicationGroupController, { Props } from "./ApplicationGroupController.web"
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowForwardIos';
const CompanyNameInput = styled(TextField)({
    width: "520px",
    height: "60px",
    borderRadius: "5px",
})

// Customizable Area End

export default class ApplicationGroup extends ApplicationGroupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderDialogContentApplicationGroup = () => {
        const { dialogMode2, industry2 } = this.state;

        let title = "";
        let inputField = null;

        if (dialogMode2 === "add") {
            title = "Add New Application Group";
            inputField = (
                <>
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#334155",
                            paddingBottom: "4px",
                            fontFamily: "Inter",
                        }}
                    >
                        Industry / Market<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="industry Input"
                        data-testid="industry-input"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Industry / Market"
                        value={this.state.industryName2}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#334155",
                            paddingBottom: "4px",
                            fontFamily: "Inter",

                        }}
                    >
                        Application Group<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input2"
                        data-testid="industry-input2"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Application Group"
                        value={industry2}
                        onChange={this.handleIndustryMarketApplicationGroup}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#334155",
                            paddingBottom: "8px",
                            fontFamily: "Inter",

                        }}
                    >
                        Upload Image<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Box
                        style={{
                            border: "1px solid #E2E8F0",
                            borderRadius: "8px",
                            padding: "16px",
                            backgroundColor: "#F8FAFC",

                        }}
                    >
                        <Box
                            style={{
                                border: "1px dashed #CBD5E1",
                                borderRadius: "8px",
                                textAlign: "center",
                                padding: "24px",
                                backgroundColor: "#FFFFFF",

                            }}
                        >
                            <input
                                id="file-input"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => this.handleFileChangeApplicationGroup(e)}
                            />
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#F97316", width: "92px", height: "34px",fontFamily: "Inter", borderRadius: "12px", color: "#FFFFFF", fontSize: "16px" }}
                            >
                                Upload
                            </Button>
                            <Typography style={{ margin: "8px 0", fontSize: "14px", color: "#334155" }}>
                                OR
                            </Typography>
                            <Typography
                                component="label"
                                htmlFor="file-input"
                                style={{
                                    cursor: "pointer",
                                    color: "#F97316",
                                    fontSize: "14px",
                                    textDecoration: "underline",
                                    fontFamily: "Inter",

                                }}
                            >
                                Browse Files
                            </Typography>
                            {this.state.selectedFile && (
                                <Typography style={{ marginTop: "16px", fontSize: "14px", color: "#334155" }}>
                                    Selected File: {this.state.selectedFile.name}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </>
            );
        } else if (dialogMode2 === "edit") {
            title = "Edit Industry / Market";
            inputField = (
                <>
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#334155",
                            paddingBottom: "4px",

                        }}
                    >
                        Industry / Market<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input2"
                        data-testid="industry-input2"
                        variant="outlined"
                        size="small"
                        placeholder="Edit Industry / Market"
                        value={this.state.industryName2}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#334155",
                            paddingBottom: "4px",
                            fontFamily: "Inter",
                        }}
                    >
                        Application Group<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input2"
                        data-testid="industry-input2"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Application Group"
                        value={industry2}
                        onChange={this.handleIndustryMarketApplicationGroup}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "16px",

                        }}
                    >
                        <Typography
                            style={{
                                fontSize: "14px",
                                fontWeight: 700,
                                color: "#334155",
                                paddingBottom: "8px",
                                flex: 1,
                                fontFamily: "Inter",
                            }}
                        >
                            Upload Image<span style={{ color: "red" }}> *</span>
                        </Typography>
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",

                            }}
                        >
                            <Typography
                                component="span"
                                style={{
                                    color: "rgba(255, 102, 0, 1)",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    fontWeight: 400,
                                    width: "94px",
                                    height: "22px",
                                    textDecoration: "underline",
                                    fontFamily: "Inter",
                                }}
                                onClick={() => document.getElementById("file-input")?.click()}
                            >
                                Update Image
                            </Typography>

                            <input
                                id="file-input"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => this.handleFileChangeApplicationGroup(e)}
                            />
                        </Box>
                    </Box>

                    <Box
                        style={{
                            border: "1px solid #E2E8F0",
                            borderRadius: "8px",
                            padding: "16px",
                            backgroundColor: "#F8FAFC",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",

                        }}
                    >
                        {/* Image Display Box */}
                        <Box
                            style={{
                                border: "1px dashed #CBD5E1",
                                borderRadius: "8px",
                                width: "100%",
                                height: "256px",
                                padding: "24px",
                                backgroundColor: "#FFFFFF",
                                textAlign: "center",
                            }}
                        >
                            {this.state.imageUrl ? (
                                <Box>
                                    <img
                                        src={this.state.imageUrl}
                                        alt="Selected"
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "200px",
                                            borderRadius: "8px",
                                            border: "1px solid #E2E8F0",
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            marginTop: "8px",
                                            fontSize: "14px",
                                            color: "#334155",
                                        }}
                                    >
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography
                                    style={{
                                        fontSize: "14px",
                                        color: "#334155",
                                    }}
                                >
                                    No Image Selected
                                </Typography>
                            )}
                        </Box>
                    </Box>

                </>
            );
        } else if (dialogMode2 === "delete") {
            title = "Delete Industry / Market";
            inputField = (
                <Typography
                    style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#334155",
                        paddingBottom: "4px",
                        marginTop: "62px",
                        marginLeft: '20px',
                        fontFamily: "Inter",

                    }}
                >
                    Are you sure you want to delete <b>{this.state.industry2}</b> ApplicationGroup from ApplicationGroup?

                </Typography>
            );
        }

        return (
            <>
                <Box
                    display={"flex"}
                    style={{
                        cursor: "pointer",
                        justifyContent: "space-between",
                        margin: "5%",
                        marginBottom: "0px",

                    }}
                    data-testid="close-dialog-button"
                    onClick={this.handleCloseApplicationGroup}
                >
                    <Typography
                        style={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: "#272727",
                            fontFamily: "Inter",

                        }}
                    >
                        {title}
                    </Typography>
                    <CloseIcon />
                </Box>
                <DialogContent
                    style={{
                        lineHeight: "26px",
                        padding: "22px",
                        color: "#000000",
                        fontSize: "24px",
                        overflowX: "hidden",
                        overflowY: "hidden",
                        fontWeight: 700,
                    }}
                >
                    <div style={{ width: "100%", border: "1px solid #E2E8F0" }}></div>
                    <Box style={{ marginTop: "35px" }}>{inputField}</Box>
                    {this.state.alertVisible && (
                        <div data-testid="alert" className="alert">
                            {this.state.alertmessage}
                        </div>
                    )}
                    {dialogMode2 !== "delete" && (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                marginTop: "19px",
                                gap: "20px",
                            }}
                        >
                            <Button
                                data-testid="action-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "12px",
                                    width: "80px",
                                    fontFamily: "Inter",
                                    border: "1px solid #FF6600",
                                    fontSize: "16px",
                                    backgroundColor: "#FF6600",
                                    color: "white",
                                    fontWeight: 700,
                                    gap: "8px",
                                    textTransform: "capitalize",
                                }}
                                onClick={
                                    dialogMode2 === "add"
                                        ? this.addNewApplicationGroup
                                        : this.editApplicationGroup

                                }
                            >
                                {dialogMode2 === "add" ? "Add" : "Save"}
                            </Button>
                        </Box>
                    )}
                    {dialogMode2 === "delete" && (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "right",
                                marginTop: "50px",
                                gap: "20px",
                                fontFamily: "Inter",
                            }}
                        >
                            <Button
                                data-testid="no-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "8px",
                                    width: "70px",
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    gap: "8px",
                                    backgroundColor: "#E0E0E0",
                                    color: "#000000",
                                    fontWeight: 700,
                                    textTransform: "capitalize",
                                }}
                                onClick={this.handleCloseApplicationGroup}
                            >
                                No
                            </Button>
                            <Button
                                data-testid="yes-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "12px",
                                    width: "61px",
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    backgroundColor: "#FF0000",
                                    color: "white",
                                    gap: "8px",
                                    fontWeight: 700,
                                    textTransform: "capitalize",
                                }}
                                onClick={this.deleteApplicationGroup}
                            >
                                Yes
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </>
        );
    };

    cartTableApplicationGroup = () => {
        const { industryData3 } = this.state;
        const itemsPerRow = 7;
        const rows1 = [];

        for (let i = 0; i < industryData3.length; i += itemsPerRow) {
            const rowItems = industryData3.slice(i, i + itemsPerRow);

            rows1.push(
                <TableRow
                    key={`row-${i}`}
                    style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
                        gap: "10px",

                    }}
                >
                    {rowItems.map((item, index) => (
                        <TableCell
                            key={`cell-${i}-${index}`}
                            style={{
                                border: "none",
                                padding: "8px",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: "Inter",

                            }}
                        >
                            <Box
                                onClick={() =>
                                    this.navigateToApplicationGroup(
                                        "/Application",
                                        item.id,
                                        item.attributes.application_group_name
                                    )
                                }
                                style={{
                                    border: "1px solid #E2E8F0",
                                    borderRadius: "8px",
                                    alignItems: "flex-end",
                                    justifyContent: "center",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    textAlign: "center",
                                    color: "rgb(50, 56, 66)",
                                    position: "relative",
                                    padding: "10px",
                                    height: "100px",
                                    width: "122px",
                                    display: "flex",

                                }}
                            >
                                {item.attributes.application_group_name}
                                <IconButton
                                    aria-label="more"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.handleMenuClickApplicationGroup(
                                            event,
                                            item.id,
                                            item.attributes.application_group_name,
                                            item.attributes.image?.url || ""
                                        );
                                    }}
                                    data-testid="MoreIcon"
                                    style={{
                                        position: "absolute",
                                        top: "1px",
                                        right: "1px",
                                        padding: "1px",
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleMenuCloseApplicationGroup}
                                    PaperProps={{
                                        style: {
                                            boxShadow: "none",
                                            border: "1px solid #E7E2Ef",
                                            width: "80px",
                                        },
                                    }}
                                >
                                    <MenuItem
                                        data-testId="EditMenuItem"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.handleComOpenApplicationGroup("edit");
                                        }}
                                    >
                                        Edit
                                    </MenuItem>

                                    <MenuItem
                                        data-testId="DeleteMenuItem"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.handleComOpenApplicationGroup("delete");
                                        }}
                                    >
                                        Delete
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </TableCell>
                    ))}

                    {rowItems.length < itemsPerRow &&
                        Array(itemsPerRow - rowItems.length)
                            .fill(null)
                            .map((_, idx) => (
                                <TableCell
                                    key={`empty-cell-${i}-${idx}`}
                                    style={{
                                        border: "none",
                                        padding: "8px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                />
                            ))}
                </TableRow>
            );
        }

        return (
            <Box style={{ width: "94%", ...webStyle1.mainContainers }}>
                <Box style={webStyle1.Headers}>
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#1E293B",
                            paddingBottom: "4px",
                            fontFamily: "Inter",
                        }}
                        onClick={() => this.navigateTooApplicationGroup("/ApplicationIndustry")}
                    >
                        Industry / Market
                    </Typography>
                    <Typography style={{ marginLeft: "6px", marginBottom: "6px" }}>
                        <ArrowBackIcon style={{ color: "#64748B", fontSize: "14px" }} />
                    </Typography>
                    <Typography
                        style={{
                            marginLeft: "6px",
                            marginBottom: "5px",
                            color: "#565E6C",
                            fontWeight: "normal",
                            fontSize: "14px",
                            fontFamily: "Inter",
                        }}
                    >
                        {this.state.industryName2}
                    </Typography>
                </Box>
                <Box style={webStyle1.Header}>
                    <Typography variant="h6" style={webStyle1.Heading}>
                        {this.state.industryName2}
                    </Typography>
                    <Button
                        variant="contained"
                        data-testid="open-dialog-button"
                        onClick={() => this.handleComOpenApplicationGroup("add")}
                        style={{
                            ...webStyle1.CheckoutButton,
                            textTransform: "none",
                            boxShadow: "none",
                        }}
                    >
                        Add New Application Group
                    </Button>
                </Box>
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
                    <TableContainer style={{}}>
                        <Table aria-label="Industry Market Table">
                            <TableBody style={webStyle1.Tbodys}>{rows1}</TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px 16px",
                            borderTop: "1px solid #E7E2F0",

                        }}
                    >
                        <Typography
                            style={{

                                fontSize: "12px",
                                fontWeight: 400,
                                color: "#64748B",

                            }}
                        >
                            {this.getResultTextApplicationGroup(this.state.currentPage2)}
                        </Typography>
                        <Pagination
                            count={Math.ceil(
                                this.state.totalResults2 / this.state.resultperPage2
                            )}
                            page={this.state.currentPage2}
                            boundaryCount={1}
                            siblingCount={0}
                            onChange={this.handlePageChangeApplicationGroup}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    style={{
                                        textDecoration: "underline",
                                        borderRadius: 0,
                                        background: "none",

                                        fontSize: "12px",
                                        fontWeight: 400,
                                        color: "#979797",
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };

    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigateApplicationGroup} />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.cartTableApplicationGroup()}
                </SideBar>

                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleCloseApplicationGroup}
                    style={{ borderRadius: "50px" }}
                    PaperProps={{
                        style: { borderRadius: "8px", width: "600px",fontFamily: "Inter", maxHeight: "80vh", overflowY: "scroll", },
                    }}
                    data-testid="dialog"
                >
                    <Box style={{fontFamily: "Inter", overflow: "auto", }} >
                        {this.renderDialogContentApplicationGroup()}
                    </Box>

                </Dialog>


            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle1: {
    [key: string]: {
        [property: string]: string | number
    }
} = {
    mainContainers: {
        marginLeft: "47px",

    },
    Header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",


    },
    Headers: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        margin: "20px 0px"
    },
    Heading: {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "26px",
        textAlign: "left",
        fontFamily: "Inter",

    },
    CheckoutButton: {
        backgroundColor: "#ff6600",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        width: "max-content",
    },
    Tbodys: {
        padding: "20px",

    },
    TableCell: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",

    },
    tableBody: {
        width: "1045px",
        padding: "15px",

    },


};

// Customizable Area End