import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Table,
  TableContainer,
  Tabs,
  Tab,
  TextField,
} from "@material-ui/core";
import { AddImg, downImage, FilesImg, saveImg, UploadImg } from "./assets";

import AddbrandsController, {
  Props,
} from "./AddbrandsController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"
import { Autocomplete } from "@mui/material";
// Customizable Area End

export default class Addbrands extends AddbrandsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  Addbrands = () => {
    const tabData = [
      { label: 'Brand Details', value: 0 },
      { label: 'Brand Contact Information', value: 1 },
      { label: 'Region of Operations', value: 2 },
      { label: 'Business Information', value: 3 },
      { label: 'Brand Documents', value: 4 },
    ];

    const options = [
      {
        label: "AAC Blocks 4",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
    ];

    const industryDatas = this.state.industryData.map((item) => ({
      id: item.id,
      name: item.attributes.name,
    }));


    const renderTabContent = () => {
      switch (this.state.value) {
        case 0:
          return renderBrandDetails();
        case 1:
          return renderBrandConDetails();
        case 2:
          return renderOtherTabs();
        case 3:
          return renderBusinessInformation();
        default:
          return renderBrandDocument();
      }
    };



    const renderBrandDetails = () => {
      const { errors } = this.state;

      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Name</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  placeholder="Enter Brand Name"
                  onChange={(e) => this.handleChange(e, "brandName")}
                  value={this.state.brandName}
                  error={!!errors?.brandName}
                  helperText={errors?.brandName}
                  inputProps={{
                    'data-testid': 'brand-name-input',
                    style: { fontWeight: 400, fontSize: "16px", fontFamily: "Inter", color: "#BCC1CA" },
                  }}
                  style={webStyle.formField}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B" },
                  }}
                />
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Industry / Sector</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  disablePortal
                  id="catalogue-field"
                  options={industryDatas}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Industry / Sector"
                      error={!!errors?.industrySector}
                      helperText={errors?.industrySector}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "14px",
                          fontFamily: "Inter",
                          color: "#1E293B",
                          padding: "6px 8px",
                          height: "40px",
                          borderRadius: "8px",
                          background: "#F3F4F6",
                        },
                        endAdornment: (
                          <img
                            alt="Dropdown Icon"
                            src={downImage}
                            style={{ width: "12px", height: "7px", marginRight: "8px" }}
                          />
                        ),
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      maxHeight: "200px",
                      boxShadow: "none",
                      border: "1px solid #F3F4F6",
                      borderRadius: "8px",
                    },
                  }}
                  onChange={(event, value) => {
                    this.setState({ industrySector: value?.id || "" });
                  }}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Brand Tagline</Typography>
                <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
              </Box>
              <TextField
                placeholder="Enter Brand Tagline"
                onChange={(e) => this.handleChange(e, "brandTagline")}
                value={this.state.brandTagline}
                error={!!errors?.brandTagline}
                helperText={errors?.brandTagline}
                inputProps={{
                  style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA" },
                }}
                style={{
                  ...webStyle.formField,
                  width: "100%",
                  height: "40px",
                  borderRadius: "8px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B" },
                }}
              />
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Logo</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Box
                  style={{
                    height: "256px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F3F4F6",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => this.handleFileChange(e, `qualityFile1`)}
                    data-testId="product-brand-image"
                  /> {this.state.qualityFile1Name && (
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginLeft: "10px",
                        color: "blue",
                        paddingBottom: "10px",
                      }}
                    >
                      {this.state.qualityFile1Name}
                    </Typography>
                  )}
                  <label htmlFor="upload-file">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label>
                  <br />
                  {errors?.qualityFile1 && (
                    <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                      {errors.qualityFile1}
                    </Typography>
                  )}
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      marginTop: "3px",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    OR
                  </Typography>
                  <Box style={{ display: "flex", marginLeft: "7px" }}>
                    <label htmlFor="upload-file" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                      <Typography
                        component="span"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#FF6600",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: 400,
                          marginTop: "8px",
                        }}
                      >
                        Browse Files
                      </Typography>
                      <img
                        src={FilesImg}
                        alt="FilesImg"
                        style={{ width: "18px", height: "18px", margin: "7px 8px" }}
                      />
                    </label>
                    <input
                      type="file"
                      id="upload-file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => this.handleFileChange(e, "qualityFile1")}
                    />
                  </Box>
                </Box>
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Description</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  placeholder="Description"
                  onChange={(e) => this.handleChange(e, "brandDescription")}
                  value={this.state.brandDescription}
                  error={!!errors?.brandDescription}
                  helperText={errors?.brandDescription}
                  inputProps={{
                    style: {
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Inter",
                      color: "#BCC1CA",
                    },
                  }}
                  style={{
                    ...webStyle.formField,
                    width: "100%",
                    height: "257px",
                    borderRadius: "8px",
                    background: "#F3F4F6",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "8px",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box onClick={this.handleSubmit} style={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
            </Box>
          </Box>
        </>
      );
    };
    const renderBrandDocument = () => {
      const { errors } = this.state;

      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Certificate Name</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  placeholder="Enter Certificate Name"
                  value={this.state.certificateName}
                  onChange={(e) => this.handleChange(e, "certificateName")}
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA" },
                  }}
                  style={{ ...webStyle.formField, width: "100%", height: "40px", borderRadius: "8px" }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "8px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B" },
                  }}
                />
                {errors?.certificateName && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.certificateName}
                  </Typography>
                )}
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>License</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  id="catalogue-field"
                  disablePortal
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a License"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "16px", fontWeight: 400, fontStyle: "Inter", color: "#BCC1CA" },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: { fontSize: "14px", fontFamily: "Inter", color: "#1E293B", padding: "10px" },
                      }}
                    />
                  )}
                />
                {errors?.license && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.license}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box>
                <Box
                  style={{
                    height: "256px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F3F4F6",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file-2"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => this.handleFileChange(e, "qualityFile2")}
                  />
                  {this.state.qualityFile2Name && (
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginLeft: "10px",
                        color: "blue",
                        paddingBottom: "10px",
                      }}
                    >
                      {this.state.qualityFile2Name}
                    </Typography>
                  )}

                  <label htmlFor="upload-file-2">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label>
                  <br />

                  <Typography
                    style={{
                      fontFamily: "Inter",
                      marginTop: "3px",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    OR
                  </Typography>

                  <Box style={{ display: "flex", marginLeft: "7px" }}>
                    <label
                      htmlFor="upload-file-2"
                      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    >
                      <Typography
                        component="span"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#FF6600",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: 400,
                          marginTop: "8px",
                        }}
                      >
                        Browse Files
                      </Typography>
                      <img
                        src={FilesImg}
                        alt="FilesImg"
                        style={{ width: "18px", height: "18px", margin: "7px 8px" }}
                      />
                    </label>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  style={{
                    height: "256px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F3F4F6",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file-3"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => this.handleFileChange(e, "qualityFile3")}
                  />

                  {this.state.qualityFile3Name && (
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginLeft: "10px",
                        color: "blue",
                        paddingBottom: "10px",
                      }}
                    >
                      {this.state.qualityFile3Name}
                    </Typography>
                  )}

                  <label htmlFor="upload-file-3">
                    <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                  </label>
                  <br />

                  <Typography
                    style={{
                      fontFamily: "Inter",
                      marginTop: "3px",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    OR
                  </Typography>

                  <Box style={{ display: "flex", marginLeft: "7px" }}>
                    <label
                      htmlFor="upload-file-3"
                      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                    >
                      <Typography
                        component="span"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#FF6600",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: 400,
                          marginTop: "8px",
                        }}
                      >
                        Browse Files
                      </Typography>
                      <img
                        src={FilesImg}
                        alt="FilesImg"
                        style={{ width: "18px", height: "18px", margin: "7px 8px" }}
                      />
                    </label>
                  </Box>
                </Box>
              </Box>

              {/* Error Message */}
              {errors?.fileUpload && (
                <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                  {errors.fileUpload}
                </Typography>
              )}
            </Box>

            <Box onClick={this.postAddBrandApi} style={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
            </Box>
          </Box>
        </>
      );
    };


    const renderBusinessInformation = () => {
      const { errors } = this.state;
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Legal Business Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "legalName")}
                  value={this.state.legalName}
                  placeholder="Legal Business Name"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
                {errors?.legalName && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.legalName}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Tax Identification Number (TIN/VAT/GST)*</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "taxNumber")}
                  value={this.state.taxNumber}
                  placeholder="Tax Identification Number"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      color: "#1E293B",
                    },
                  }}
                />
                {errors?.taxNumber && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.taxNumber}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box>
              <Typography style={{ ...webStyle.Title }}>
                Business Address
              </Typography>
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Street</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "street")}
                  value={this.state.street}
                  placeholder="Enter Street"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
                {errors?.street && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.street}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>City</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "city")}
                  value={this.state.city}
                  placeholder="Enter City"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      color: "#1E293B",
                    },
                  }}
                />
                {errors?.city && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.city}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "24px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>State</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "state")}
                  value={this.state.state}
                  placeholder="Enter State"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
                {errors?.state && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.state}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Country</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "country")}
                  value={this.state.country}
                  placeholder="Enter Country"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      color: "#1E293B",
                    },
                  }}
                />
                {errors?.country && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.country}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Zip Code</Typography></Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "zipcode")}
                  value={this.state.zipcode}
                  placeholder="Enter Zip Code"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box onClick={this.handleSave} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderBrandConDetails = () => {
      const { errors } = this.state;
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Primary Contact Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "primaryConName")}
                  value={this.state.primaryConName}
                  placeholder="Enter Contact Name"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
                {errors?.primaryConName && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.primaryConName}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Primary Phone Number</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "primaryPhoneNum")}
                  value={this.state.primaryPhoneNum}
                  placeholder="Enter Phone Number"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      color: "#1E293B",
                    },
                  }}
                />
                {errors?.primaryPhoneNum && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.primaryPhoneNum}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Alternative Contact Information</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "alternativeContact")}
                  value={this.state.alternativeContact}
                  placeholder="Enter Alternative Contact Information"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
                {errors?.alternativeContact && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.alternativeContact}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Customer Support Contact Details</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "customerSupport")}
                  value={this.state.customerSupport}
                  placeholder="Enter Customer Support Contact Details"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
                {errors?.customerSupport && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.customerSupport}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Email Address</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "emailAddress")}
                  value={this.state.emailAddress}
                  placeholder="Enter Email Address"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
                {errors?.emailAddress && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.emailAddress}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box onClick={this.handleSaveBrandConDetails} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };

    const renderOtherTabs = () => {
      const { errors } = this.state;
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={{ ...webStyle.formGrid }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Select City</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  id="catalogue-field"
                  disablePortal
                  open={this.state.isAutocompleteOpen}
                  onOpen={() => this.setState({ isAutocompleteOpen: true })}
                  onClose={this.handleAutocompleteClose}
                  options={options}
                  onChange={(event, value) => this.setState({ selectedCity: value })}
                  data-testid="autocomplete-dropdown"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          fontSize: "16px",
                          fontWeight: 400,
                          fontStyle: "Inter",
                          color: "#BCC1CA",
                        },
                      }}
                      placeholder="Select State"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "14px",
                          fontFamily: "Inter",
                          color: "#1E293B",
                          padding: "10px",
                          height: "40px",
                          borderRadius: "8px",
                          background: "#F3F4F6",
                        },
                        endAdornment: (
                          <img
                            src={downImage}
                            alt="Dropdown Icon"
                            style={{ width: "12px", height: "7px", marginRight: "8px" }}
                          />
                        ),
                      }}
                      data-testid="autocomplete-input"
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      borderRadius: "8px",
                      maxHeight: "200px",
                      boxShadow: "none",
                      border: "1px solid #F3F4F6",
                    },
                  }}
                />
                {errors?.city && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.city}
                  </Typography>
                )}
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Region</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "region")}
                  value={this.state.region}
                  placeholder="Enter Region"
                  inputProps={{
                    'data-testid': 'brand-name-input',
                    style: {
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Inter",
                      color: "#BCC1CA",
                    },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter", color: "#1E293B", },
                  }}
                />
                {errors?.region && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.region}
                  </Typography>
                )}
              </Box>
              <Box style={{ ...webStyle.formGrid }}>
             <Box  onClick={this.handleAddCityClick} style={{display:"flex"}}><img src={AddImg} alt="AddImg"/><Typography style={{...webStyle.AddType, margin: "auto 8px"}}>Add another City</Typography> </Box>

              </Box>
            </Box>
          </Box>
          <Box onClick={this.handleSaveOtherTabs} style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={saveImg} alt="saveImg" style={webStyle.saveButton} />
          </Box>
        </>
      );
    };
    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography style={webStyle.Headingss}>
            Add New Brand
          </Typography>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px" }}
                    label={
                      <Typography
                        style={this.state.value === value ? webStyle.activeTab : webStyle.inactiveTab}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              {renderTabContent()}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Addbrands()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter",
  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter",
  },
  Headingss: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "1.5",
    textAlign: "left" as React.CSSProperties["textAlign"]
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
    alignItems: "start",
  },
  formItem: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',
    width: '100%' as string,
  },
  AddType: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FF6600",
    marginLeft: "8px"
  },
  formField: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    background: '#F3F4F6',
    boxSizing: 'border-box' as 'border-box',
  } as React.CSSProperties,
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334155",
  },
  textFieldStyle: {
    fontSize: "14px",
    fontWeight: 400,
    backgroundColor: "#F3F4F6",
    border: "none",
    borderRadius: "8px",
    boxShadow: "none",
  },
  uploadContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  uploadButton: {
    backgroundColor: "#ff6600",
    color: "#fff",
    textTransform: "none",
    padding: "6px 12px",
    borderRadius: "8px",
  },
  saveButton: {
    margin: "16px 20px 16px 0px",
    boxShadow: "none",
  },
  formHeading: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "20px",
  },
  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  navTabContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px",
    borderBottom: "1px solid #E1E7EF"
  },
  activeTab: {
    fontFamily: 'Inter',
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 700,
    textAlign: 'center' as 'center',
    color: '#EE6900',
  },
  inactiveTab: {
    fontFamily: 'Inter    ',
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'left' as 'left',
    color: '#565E6C',
  },
  Title: {
    fontFamily: 'Inter',
    marginBottom: "20px",
    fontSize: '20px',
    fontWeight: 600,
  }
}
// Customizable Area End