import React from "react";

import {
  // Customizable Area Start
    Backdrop,
    Box,
    Button,
    CardContent,
    Fade,
    MenuItem,
    Modal,
    TextField,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CatalougesController, {
    Props
} from "./CatalougesController";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import { Deleteimg, UploadImg , downImg} from "./assets";
// Customizable Area End

export default class Catalouges extends CatalougesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    Catalougedata = () => {

        const catalogues = Array.from({ length: 14 }, (_, i) => `Catalogue ${i + 1}`);

        return (
            <Box style={styles.mainContainer}>
                <Box style={styles.header}>
                    <Typography variant="h5" style={styles.heading}>
                        Brand Catalogue
                    </Typography>
                    <Button
                        style={styles.checkoutButton}
                        onClick={this.handleOpenModal}
                        data-testId="MapProduct"
                    >
                        Add New Catalogue                   
                    </Button>
                </Box>
                <Box
                    data-testId="Catalouges"
                    style={styles.catalogueContainer}
                    onClick={this.navigateToCreditnoteDetail}
                >
                    {catalogues.map((catalogue, index) => (
                        <Box key={index} style={styles.catalogueItem}>
                            <CardContent>
                                <Typography style={styles.catalogueText}>
                                    {catalogue}
                                </Typography>
                            </CardContent>
                        </Box>
                    ))}
                </Box>
                <Modal
  open={this.state.openModal}
  onClose={this.handleCloseModal}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{ timeout: 500 }}
>
  <Fade in={this.state.openModal}>
    <Box
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 708,
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px 24px 24px 24px",
          borderBottom: "1px solid #E2E8F0",
        }}
      >
        <Typography style={{ ...styles.modalTitle }}>
          Add New Catalogue
        </Typography>
        <img
          src={Deleteimg}
          alt="Close Modal"
          onClick={this.handleCloseModal}
          style={{ width: "14px", height: "14px", cursor: "pointer", margin: "auto 0px" }}
        />
      </Box>
      <Box
        style={{
          padding: "24px 24px 0px 24px",
        }}
      >
        <label
          htmlFor="catalogue-field"
          style={{
            display: "block",
            fontSize: "14px",
            fontWeight: 700,
            color: "#1E293B",
            marginBottom: "8px",
            fontFamily: "Inter"
          }}
        >
          Name<span style={{ color: "red" }}>*</span>
        </label>
        <TextField
          required
          value={this.state.catalogueName}
          onChange={this.handleCatalogueNameChange}
          inputProps={{ "data-testid": "Name-input" }}
          placeholder="Catalogue 14"
          style={{
            width: "100%",
            height: "44px",
            borderRadius: "8px",
            background: "#F3F4F6",
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              padding: "10px",
              fontSize: "14px",
              color: "#1E293B",
            },
          }}
        />
      </Box>
      <Box
        style={{
          padding: "24px 24px 0px 24px",
        }}
      >
        <label
          htmlFor="catalogue-field"
          style={{
            display: "block",
            fontSize: "14px",
            fontWeight: 700,
            color: "#1E293B",
            marginBottom: "8px",
            fontFamily: "Inter"
          }}
        >
          Category<span style={{ color: "red" }}>*</span>
        </label>
        <TextField
          required
          select
          value={this.state.category}
          onChange={this.handleCategoryChange}
          placeholder="Select Product"
          style={{
            width: "100%",
            height: "44px",
            borderRadius: "8px",
            background: "#F3F4F6",
          }}
          SelectProps={{
            IconComponent: () => (
              <img
                src={downImg}
                alt="Dropdown Icon"
                style={{ width: "12px", height: "7px", marginRight: "8px" }}
              />
            ),
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              padding: "10px",
              fontSize: "14px",
              color: "#1E293B",
            },
          }}
        >
          <MenuItem value="Category 1">Category 1</MenuItem>
          <MenuItem value="Category 2">Category 2</MenuItem>
        </TextField>
      </Box>
      <Box
        style={{
          padding: "24px 24px 24px 24px",
          borderBottom: "1px solid #E2E8F0",
        }}
      >
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 700,
            marginBottom: "16px",
            color: "#1E293B",
            fontFamily: "Inter"
          }}
        >
          Upload Image<span style={{color : "red"}}>*</span>
        </Typography>
        <Box
          style={{
            height: "256px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#F3F4F6",
          }}
        >
          <input
            type="file"
            id="upload-file"
            style={{ display: "none" }}
            onChange={this.handleFileUpload}
            data-testId = "product-brand-image"
          />
          <label htmlFor="upload-file">
            <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
          </label><br />
          <Typography style={{fontFamily:"Inter", marginTop:"3px", fontSize : "14px" , fontWeight : 400}}>OR</Typography>
          <Typography component="span" style={{ textDecoration: "underline", cursor: "pointer", color:"#FF6600", fontFamily : "Inter"
                    ,fontSize:"14px", fontWeight:400, marginTop: "8px"
                 }}>
                  Browse Files
                </Typography>
        </Box>
      </Box>
      <Box
        style={{
          padding: "24px",
          display: "flex",
          justifyContent: "flex-end",
          borderTop: "1px solid #E2E8F0",
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: "#FF6600",
            color: "#fff",
            padding: "8px 30px",
            borderRadius: "8px",
            fontWeight: 700,
            fontSize: "14px",
            boxShadow:"none"
          }}
        >
          Add
        </Button>
      </Box>
    </Box>
  </Fade>
</Modal>
            </Box>
        );
    }


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Usernavbar  />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.Catalougedata()}
                </SideBar>
            </Box>      
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    pageContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
    },
    mainContainer: {
        width: "94%",
        margin: "0px 20px 0px 45px",
        fontFamily: "Inter",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",
    },
    heading: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "26px",
    },
    checkoutButton: {
        backgroundColor: "#ff6600",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        width: "198px",
        textTransform: "none" as const, 
        boxShadow: "none",
    },
    Addbutton :{
            backgroundColor: "#FF6600",
            color: "white",
            align: "right",
            fontSize : "16px",
            fontWeight: 700,
            fontFamily : "Inter",
            boxShadow : "none",
            textTransform: "none" as const,
            borderRadius:"12px"
    },
    catalogueContainer: {
        display: "flex",
        flexWrap: "wrap" as const, 
        border: "1px solid #E2E7Ef",
        borderRadius: "8px",
        padding: "30px",
        gap: "20px",
    },
    catalogueItem: {
        height: "120px",
        width: "130px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #DEE1E633",
        backgroundColor: "#FBFBFB",
        boxShadow: "none",
        borderRadius: "8px",
    },
    catalogueText: {
        fontWeight: 400,
        fontSize: "12px",
        textAlign: "center" as "center", 
        fontFamily: "Inter",
        padding: "90px 31px 10px 31px",
        width: "75px",
        color: "#323842",
      },
      modalBox: {
        position: "absolute" as "absolute", 
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "24px",
        width: "708px",
      },
      modalHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E2E8F0",
        paddingBottom: "16px",
        marginBottom: "16px",
      },
      modalTitle: {
        fontWeight: 700,
        fontSize: "30px",
        fontFamily : "Inter"
      },
      formField: {
        marginBottom: "16px",
        width: "100%",
      },
      uploadBox: {
        height: "256px",
        border: "1px dashed #cccccc",
        textAlign: "center" as React.CSSProperties["textAlign"], 
        borderRadius: "8px",
        color: "#555555",
        fontSize: "14px",
        cursor: "pointer",
        backgroundColor: "#f9f9f9",
        width: "660px",
        display: "flex",
        flexDirection: "column" as React.CSSProperties["flexDirection"], 
        justifyContent: "center",
        alignItems: "center",
      },
      uploadButton: {
        display: "block",
        backgroundColor: "#F6AD55",

        color: "#ffffff",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#DD6B20",
        },
      },
      addButton: {
        marginTop: "24px",
        backgroundColor: "#F6AD55",
        color: "#ffffff",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#DD6B20",
        }
    }
}
// Customizable Area End
