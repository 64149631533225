import React from "react";


import {
    // Customizable Area Start
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Dialog,
    DialogContent,
    styled,
    TextField,
    TableCell,
    IconButton,
    Menu,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar.web"
import Navbar from "../../../components/src/Navbar.web";
import ApplicationIndustryController, { Props } from "./ApplicationIndustryController.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';

const CompanyNameInput = styled(TextField)({
    width: "520px",
    height: "60px",
    borderRadius: "5px",
})

// Customizable Area End

export default class ApplicationIndustry extends ApplicationIndustryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderDialogContent = () => {
        const { dialogMode, industry } = this.state;

        let title = "";
        let inputField = null;

        if (dialogMode === "add") {
            title = "Add New Industry / Market";
            inputField = (
                <>
                    <Typography
                        style={webStyle.tabfield}
                    >
                        Industry / Market<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input"
                        data-testid="industry-input"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Industry / Market"
                        value={industry}
                        onChange={this.handleIndustryMarket}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                </>
            );
        } else if (dialogMode === "edit") {
            title = "Edit Industry / Market";
            inputField = (
                <>
                    <Typography
                        style={webStyle.tabfield}
                    >
                        Industry / Market<span style={{ color: "red" }}> *</span>
                    </Typography>
                    <CompanyNameInput
                        aria-label="Industry Input"
                        data-testid="industry-input"
                        variant="outlined"
                        size="small"
                        placeholder="Edit Industry / Market"
                        value={industry}
                        onChange={this.handleIndustryMarket}
                        InputProps={{
                            style: { borderRadius: "5px", border: "1px solid transparent" },
                        }}
                    />
                </>
            );
        } else if (dialogMode === "delete") {
            title = "Delete Industry / Market";
            inputField = (
                <Typography
                    style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#334155",
                        paddingBottom: "4px",
                        marginTop: "62px",
                        marginLeft: '20px',
                        fontFamily: "Inter",

                    }}
                >
                    Are you sure you want to delete <b>{this.state.industry}</b> Industry from Industry/Market?

                </Typography>
            );
        }

        return (
            <>
                <Box
                    display={"flex"}
                    style={{
                        cursor: "pointer",
                        justifyContent: "space-between",
                        margin: "5%",
                        marginBottom: "0px",

                    }}
                    data-testid="close-dialog-button"
                    onClick={this.handleClose}
                >
                    <Typography
                        style={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: "#272727",
                            fontFamily: "Inter",

                        }}
                    >
                        {title}
                    </Typography>
                    <CloseIcon />
                </Box>
                <DialogContent
                    style={{
                        lineHeight: "26px",
                        padding: "22px",
                        color: "#000000",
                        fontSize: "24px",
                        overflowX: "hidden",
                        overflowY: "hidden",
                        fontWeight: 700,
                    }}
                >
                    <div style={{ width: "100%", border: "1px solid #E2E8F0" }}></div>
                    <Box style={{ marginTop: "35px", }}>{inputField}</Box>
                    {this.state.alertVisible && (
                        <div data-testid="alert" className="alert">
                            {this.state.alertMessage}
                        </div>
                    )}
                    {dialogMode !== "delete" && (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                marginTop: "19px",
                                gap: "20px",
                                fontFamily: "Inter",
                            }}
                        >
                            <Button
                                data-testid="action-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "12px",
                                    width: "80px",
                                    fontFamily: "Inter",
                                    border: "1px solid #FF6600",
                                    fontSize: "16px",
                                    backgroundColor: "#FF6600",
                                    color: "white",
                                    fontWeight: 700,
                                    textTransform: "capitalize",
                                }}
                                onClick={
                                    dialogMode === "add"
                                        ? this.addNewIdustry
                                        : dialogMode === "edit"
                                            ? this.editIndustry
                                            : undefined
                                }
                            >
                                {dialogMode === "add" ? "Add" : "Save"}
                            </Button>
                        </Box>
                    )}
                    {dialogMode === "delete" && (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "right",
                                marginTop: "50px",
                                gap: "20px",
                                fontFamily: "Inter",
                            }}
                        >
                            <Button
                                data-testid="no-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "12px",
                                    width: "70px",
                                    fontSize: "16px",
                                    backgroundColor: "#E0E0E0",
                                    color: "#000000",
                                    fontFamily: "Inter",
                                    gap: "8px",
                                    fontWeight: 700,
                                    textTransform: "capitalize",
                                }}
                                onClick={this.handleClose}
                            >
                                No
                            </Button>
                            <Button
                                data-testid="yes-button"
                                style={{
                                    height: "44px",
                                    borderRadius: "12px",
                                    fontFamily: "Inter",
                                    width: "61px",
                                    fontSize: "16px",
                                    backgroundColor: "#FF0000",
                                    color: "white",
                                    fontWeight: 700,
                                    textTransform: "capitalize",
                                    gap: "8px"
                                }}
                                onClick={this.deleteIndustry}
                            >
                                Yes
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </>
        );
    };

    cartTable = () => {
        const { industryData } = this.state;
        const itemsPerRow = 7;
        const rows = [];

        for (let i = 0; i < industryData.length; i += itemsPerRow) {
            const rowItems = industryData.slice(i, i + itemsPerRow);

            rows.push(
                <TableRow
                    key={`row-${i}`}
                    style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
                        gap: "20px",
                        justifyContent: "start",
                        alignItems: "center",
                    }}
                >
                    {rowItems.map((item, index) => (
                        <TableCell
                            key={`cell-${i}-${index}`}
                            style={{
                                border: "none",
                                padding: "8px",
                                display: "flex",
                                justifyContent: "center",
                                fontFamily: "Inter",

                            }}
                        >
                            <Box
                                onClick={() =>
                                    this.navigateTo(
                                        "/ApplicationGroup",
                                        item.id,
                                        item.attributes.name
                                    )
                                }
                                style={{
                                    border: "1px solid #E2E8F0",
                                    borderRadius: "8px",
                                    padding: "10px",
                                    height: "100px",
                                    width: "122px",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "center",
                                    fontWeight: 400,

                                    fontSize: "12px",
                                    textAlign: "center",
                                    color: "rgb(50, 56, 66)",
                                    position: "relative",
                                }}
                            >
                                {item.attributes.name}
                                <IconButton
                                    aria-label="more"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        this.handleMenuClick(event, item.id, item.attributes.name);
                                    }}
                                    data-testid="MoreIcon"
                                    style={{
                                        position: "absolute",
                                        top: "1px",
                                        right: "1px",
                                        padding: "1px",
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleMenuClose}
                                    PaperProps={{
                                        style: {
                                            boxShadow: "none",
                                            border: "1px solid #E7E2Ef",
                                            width: "80px",
                                        },
                                    }}
                                >
                                    <MenuItem
                                        data-testId="EditMenuItem"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.handleComOpen("edit");
                                        }}
                                    >
                                        Edit
                                    </MenuItem>

                                    <MenuItem
                                        data-testId="DeleteMenuItem"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            this.handleComOpen("delete");
                                        }}
                                    >
                                        Delete
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </TableCell>
                    ))}

                    {rowItems.length < itemsPerRow &&
                        Array(itemsPerRow - rowItems.length)
                            .fill(null)
                            .map((_, idx) => (
                                <TableCell
                                    key={`empty-cell-${i}-${idx}`}
                                    style={{
                                        border: "none",
                                        padding: "8px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                />
                            ))}
                </TableRow>
            );
        }

        return (
            <Box style={{ width: "94%", ...webStyle.mainContainer }}>
                <Box style={webStyle.header}>
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: 700,
                            color: "#334155",
                            paddingBottom: "4px",
                            fontFamily: "Inter",

                        }}
                    >
                        Industry / Market
                    </Typography>
                </Box>
                <Box style={webStyle.header}>
                    <Typography variant="h6" style={webStyle.heading}>
                        Industry / Market
                    </Typography>
                    <Button
                        variant="contained"
                        data-testid="open-dialog-button"
                        onClick={() => this.handleComOpen("add")}
                        style={{
                            ...webStyle.checkoutButton,
                            textTransform: "none",
                            boxShadow: "none",
                        }}
                    >
                        Add New Industry / Market
                    </Button>
                </Box>
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
                    <TableContainer>
                        <Table aria-label="Industry Market Table">
                            <TableBody style={webStyle.Tbody}>{rows}</TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px 16px",
                            borderTop: "1px solid #E7E2F0",
                        }}
                    >
                        <Typography
                            style={{

                                fontSize: "12px",
                                fontWeight: 400,
                                color: "#64748B",
                            }}
                        >
                            {this.getResultText(this.state.currentPage)}
                        </Typography>
                        <Pagination
                            count={Math.ceil(
                                this.state.totalResults / this.state.resultperPage
                            )}
                            page={this.state.currentPage}
                            onChange={this.handlePageChange}
                            siblingCount={0}
                            boundaryCount={1}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    style={{
                                        textDecoration: "underline",
                                        borderRadius: 0,
                                        background: "none",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        color: "#979797",
                                    }}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };


    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px", }}>
                <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigate} />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.cartTable()}
                </SideBar>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    style={{ borderRadius: "50px" }}
                    PaperProps={{
                        style: { borderRadius: "8px", width: "600px",fontFamily: "Inter", overflowY: "scroll", height: "300px" },
                    }}
                    data-testid="dialog"
                >
                    <Box style={{ overflow: "auto",fontFamily: "Inter",}} >
                        {this.renderDialogContent()}
                    </Box>

                </Dialog>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle: {
    [key: string]: {
        [property: string]: string | number | { [property: string]: string | number }
    }
} = {
    mainContainer: {
        marginLeft: "47px",

    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",

        "@media (max-width: 768px)": {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    heading: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "26px",
        textAlign: "left",
        "@media (max-width: 768px)": {
            fontSize: "20px",
            textAlign: "center",
        },
    },
    checkoutButton: {
        backgroundColor: "#ff6600",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Inter",
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        width: "max-content",
        "@media (max-width: 768px)": {
            width: "100%",
            textAlign: "center",
        },
    },
    Tbody: {
        padding: "20px",
        "@media (max-width: 768px)": {
            padding: "10px",
        },
    },
    tableHead: {
        backgroundColor: "#f3f4f6",
    },
    tabfield:{
        fontSize: "14px",
        fontWeight: 700,
        color: "#334155",
        paddingBottom: "4px",
        fontFamily: "Inter",
    },
    TableCell: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "left",
        color: "#565E6C",
        backgroundColor: "#F3F4F6",
        "@media (max-width: 768px)": {
            fontSize: "12px",
        },
    },
    tableBody: {
        width: "1045px",
        padding: "15px",
        "@media (max-width: 768px)": {
            width: "100%",
            padding: "10px",
        },
    },
};

// Customizable Area End