import React from "react";
import {
    Box,
    Typography,
    Grid,
    Breadcrumbs,
    Hidden
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import CatagoriesController, { Props } from "./CatagoriesController";
import UserNavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"

const BreadcrumBlock = styled(Breadcrumbs)({
    display: "flex",
    "@media (max-width:600px)": {
        width: "100%"
    },
    '& .breadcrumcontent': {
        color: "white",
        fontSize: "14px",
        fontWeight: 500,
        height: "20px",
        textDecoration: "none",
        "@media (max-width:600px)": {
            marginTop: 0,
            height: 0,
        },
    },
    '& .linkcolor': {
        fontFamily: "Inter",
        color: "rgba(250, 250, 250, 0.6)",
        textDecoration: "none",
        display: "flex",
        fontWeight: 500,
        alignItems: "center",
        fontSize: "14px",
        cursor: "pointer",
        "@media(max-width:600px)": {
            fontSize: "12px"
        },
    },
    '& .rigthArrow': {
        marginRight: "10px"
    },
    "& .breadcrumItem": {
        cursor: "pointer"
    },
    '& .active': {
        fontFamily: "Inter",
        color: "rgba(250, 250, 250, 1)",
        textDecoration: "none",
    },
    '& .sublinkcolor': {
        fontFamily: "Inter",
        color: "#FAFAFA",
        textDecoration: "none",
    },

})
const Categories = styled("div")(({ theme }) => ({
    "& .category": {
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
        padding: "29px",
    },
    "& .type-txt": {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#323842",
    },
    "& .heading-txt": {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "26px",
        color: "#171A1F",
        margin: "23px 0px"
    },
    "& .button": {
        display: "flex",
        justifyContent: "center",
        marginBottom: "16px"
    },
    "& .buttonMap": {
        padding: "10px 20px",
        border: "1px solid transparent",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Inter",
        color: "#FFF",
        backgroundColor: "#FF6600",
        borderRadius: "12px",
        cursor: "pointer",
        marginRight: "14px",
        whiteSpace: "nowrap"
    },
    "& .buttonVersion": {
        padding: "10px 15px",
        border: "1px solid transparent",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Inter",
        color: "#FFF",
        backgroundColor: "#BDC1CA",
        borderRadius: "12px",
        cursor: "pointer",
        marginRight: "14px",
        whiteSpace: "nowrap"
    },
    "& .buttonUpid": {
        padding: "10px 20px",
        border: "1px solid transparent",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Inter",
        color: "#FFF",
        backgroundColor: "#FF8A3C",
        borderRadius: "12px",
        cursor: "pointer",
        whiteSpace: "nowrap"
    },
    "& .popupBack": {
        position: "fixed",
        top: 0,
        right: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(15, 23, 42, .4)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .popup": {
        backgroundColor: "white",
        border: "1px solid transparent",
        borderRadius: "8px",
        width: "auto"
    },
    "& .headerPopup": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",
        borderBottom: "1px solid #E2E8F0",
        marginBottom: "10px"
    },
    "& .headerPopup__text": {
        fontSize: "30px",
        fontWeight: 700,
        lineHeight: "32px",
        color: "#171A1F"
    },
    "& .headerPopup__close": {
        cursor: "pointer"
    },
    "& .padding24": {
        padding: "0px 24px"
    },
    "& .popup__inputText": {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16px",
        color: "#272727",
        marginTop: "20px"
    },
    "& .popup__input": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#272727",
        marginTop: "5px",
        padding: "10px 8px",
        backgroundColor: "#F3F4F6",
        border: "1px solid transparent",
        borderRadius: "8px",
        minWidth: "40vw",
        display: "flex",
        alignItems: "center"
    },
    "& .popup__input-sel": {
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#272727",
        backgroundColor: "#F3F4F6",
        border: "1px solid transparent",
        borderRadius: "8px",
        width: "calc(100% - 40px)"
    },
    "& .footerPopup": {
        borderTop: "1px solid #E2E8F0",
        marginTop: "30px",
        padding: "30px 24px",
        display: "flex",
        justifyContent: "end"
    },
    "& .footerPopup__button": {
        padding: "16px 24px",
        backgroundColor: "#FF6600",
        border: "1px solid transparent",
        borderRadius: "12px",
        color: "white",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
    }
}))
const LevelBlock = styled("div")(({ theme }) => ({
    fontFamily: 'Inter',
    "& .levelTable": {
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
        overflow: "hidden",
        width: "100%",
        fontFamily: 'Inter',
    },
    "& .levelTable__head": {
        padding: "12px 40px",
        backgroundColor: "#F3F4F6",
    },
    "& .th": {
        padding: "12px 40px",
        textAlign: "left",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#565E6C",
        "@media (max-width:900px)": {
            padding: "10px 5px",
            fontSize: "10px",
        },
    },
    "& .levelTable__body": {
        margin: "20px",
        width: "calc(100% - 40px)"
    },
    "& .type-txt-cat": {
        cursor: "pointer"
    },
    "& .levelTable__body__row td": {
        padding: "26px 20px",
        cursor: "pointer"
    }, "& .levelTable__body__row_u td": {
        padding: "10px 20px",
        cursor: "pointer",
        "@media (max-width:900px)": {
            padding: "10px 5px",
        },
    },
    "& .table__start": {
        borderLeft: "1px solid #E1E7EF",
        borderTop: "1px solid #E1E7EF",
        borderBottom: "1px solid #E1E7EF",
        marginLeft: "20px"
    },
    "& .table__middle": {
        borderTop: "1px solid #E1E7EF",
        borderBottom: "1px solid #E1E7EF",
    },
    "& .table__end": {
        borderRight: "1px solid #E1E7EF",
        borderTop: "1px solid #E1E7EF",
        borderBottom: "1px solid #E1E7EF",
    },
    "& .heading-txt": {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "26px",
        color: "#171A1F",
        margin: "23px 0px"
    },
    "& .pagination__info": {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#64748B",
    },
    "& .pagination": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    "& .pagination__number-active": {
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        color: "#979797",
        margin: "0px 2px"
    },
    "& .pagination__number": {
        fontSize: "12px",
        fontWeight: 400,
        color: "#979797",
        borderBottom: "1px solid #979797",
        margin: "0px 2px"
    },
    "& .paginationTable": {
        border: "1px solid #E1E7EF",
        padding: "20px"
    },
    "& .tableUpid": {
        width: "37%",
        "@media (max-width:900px)": {
            width: "10%",
        },
    },
    "& .tableOthers": {
        width: "25%",
    },
    "& .tableOthersActions": {
        width: "13%",
    },
    "& .tableUpidBody": {
        width: "40%",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#323842",
    },
    "& .tableOthersBody": {
        width: "25%",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#323842",
    },
    "& .tableOthersBodyActions": {
        width: "10%",
    },
    "& .upidTableContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    "& .upidTableSubname": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    "& .upidImgContainer": {
        border: "5px solid #F3EBEB",
        borderRadius: "4px",
        height: "50px",
        marginRight: "10px"
    },
    "& .upidImg": {
        height: "100%"
    },
    "& .upidTableName": {
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#323842",
        "@media (max-width:900px)": {
            fontSize: "10px"
        },
    },
    "& .MuiTypography-body1": {
        fontFamily: "Inter"
    },
    "& .upidTableSize": {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#323842",
        "@media (max-width:900px)": {
            fontSize: "10px"
        },
    },
    "& .upidTableMake": {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#323842",
        "@media (max-width:900px)": {
            fontSize: "10px"
        },
    },
    "& .upidTableCategory": {
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "20px",
        color: "#323842",
        "@media (max-width:900px)": {
            fontSize: "10px"
        },
    },
    "& .upidTableSubcategory": {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#323842",
        "@media (max-width:900px)": {
            fontSize: "10px"
        },
    },
    "& .newProductBtn": {
        padding: "10px 15px",
        borderRadius: "12px",
        backgroundColor: "#FF8A3C",
        border: "1px solid transparent",
        color: "white",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        cursor: "pointer"
    },
    "& .upidProductsContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "12px"
    }
}))
// Customizable Area End

export default class Catagories extends CatagoriesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const { selectedCategory } = this.state;
        const breadcrumbData = [...(selectedCategory || [])];
        return (<>
            <UserNavbar handleToggleSidebar={this.handleToggleSidebar} history={this.props.history} />
            <SideBar navigation={this.props.navigation} mycart={true}>
                <Box sx={{ padding: { sm: "10px", md: "34px" } }}>
                    <BreadcrumBlock maxItems={15} style={{ color: "#1E293B", fontWeight: 700, fontFamily: "Inter" }} separator={
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.49961 6.09246C7.17461 6.41746 7.17461 6.94246 7.49961 7.26746L10.7329 10.5008L7.49961 13.7341C7.17461 14.0591 7.17461 14.5841 7.49961 14.9091C7.82461 15.2341 8.34961 15.2341 8.67461 14.9091L12.4996 11.0841C12.8246 10.7591 12.8246 10.2341 12.4996 9.90913L8.67461 6.08412C8.35794 5.76746 7.82461 5.76746 7.49961 6.09246Z" fill="#64748B" />
                        </svg>
                    }
                        aria-label="breadcrumb" className="breadcrumcontent" >
                        {breadcrumbData.map((name: string, index: number) => {
                            return (
                                <Typography
                                    style={breadcrumbData?.length === index + 1
                                        ? { fontWeight: 500, color: "#565E6C", fontSize: "14px" }
                                        : { color: "#1E293B", fontWeight: 700, fontFamily: "Inter", fontSize: "14px" }}
                                    color="inherit"
                                    key={name || `breadcrumb-${index}`} // Add a fallback key
                                    onClick={() => {
                                        this.setState({
                                            selectedCategory: this.state.selectedCategory.slice(0, index + 1),
                                        });
                                    }}
                                    data-test-id="breadcrumItem"
                                    className="breadcrumItem"
                                >
                                    {name ? name.slice(0, 66) : "Unnamed"}{name && name.length > 66 ? "..." : ""}
                                </Typography>

                            )
                        })}
                    </BreadcrumBlock>
                    <Categories>
                        {selectedCategory.length == 0 && this.renderCategory()}
                        {selectedCategory.length == 1 && this.renderCategory()}
                        {this.state.selectedCategoryId && this.renderSubCategory()}
                        {this.state.dummyUpidData.length == 3 && this.renderCategoryNine()}

                        <Hidden>
                            {this.state.openPopupVersion && <div className="popupBack">
                                <Grid container direction="column" className="popup">
                                    <Grid item className="headerPopup">
                                        <Typography className="headerPopup__text">Add New Version</Typography>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="headerPopup__close" onClick={this.handlePopupVersion}>
                                            <path d="M23 10.41L21.59 9L16 14.59L10.41 9L9 10.41L14.59 16L9 21.59L10.41 23L16 17.41L21.59 23L23 21.59L17.41 16L23 10.41Z" fill="#334155" />
                                        </svg>
                                    </Grid>
                                    <Grid item className="padding24">
                                        <Typography className="popup__inputText">UPID</Typography>
                                    </Grid>
                                    <Grid item className="padding24">
                                        <input placeholder={this.state.selectedCategory[this.state.selectedCategory.length - 1]} disabled={true} className="popup__input" />
                                    </Grid>
                                    <Grid item className="popup__inputText padding24">
                                        <Typography className="popup__inputText">New Version<span style={{ color: "red" }}>*</span></Typography>
                                    </Grid>
                                    <Grid item className="padding24">
                                        <input placeholder="Enter new version" className="popup__input" onChange={this.onChangeVersion} value={this.state.newVersion} />
                                    </Grid>
                                    <Grid item className="footerPopup">
                                        <button className="footerPopup__button" onClick={this.handleNewVersion}>
                                            Add
                                        </button>
                                    </Grid>
                                </Grid>
                            </div>}
                        </Hidden>
                        <Hidden>
                            {this.state.openPopupMap && <div className="popupBack">
                                <Grid container direction="column" className="popup">
                                    <Grid item className="headerPopup">
                                        <Typography className="headerPopup__text">Map Variation</Typography>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="headerPopup__close" onClick={this.handlePopupMap}>
                                            <path d="M23 10.41L21.59 9L16 14.59L10.41 9L9 10.41L14.59 16L9 21.59L10.41 23L16 17.41L21.59 23L23 21.59L17.41 16L23 10.41Z" fill="#334155" />
                                        </svg>
                                    </Grid>
                                    <Grid item className="padding24">
                                        <Typography className="popup__inputText">UPID</Typography>
                                    </Grid>
                                    <Grid item className="padding24">
                                        <input placeholder="XXXXXXXXX01" disabled={true} className="popup__input" />
                                    </Grid>
                                    <Grid item className="popup__inputText padding24">
                                        <Typography className="popup__inputText">Version<span style={{ color: "red" }}>*</span></Typography>
                                    </Grid>
                                    <Grid item className="padding24">
                                        <div className="popup__input">
                                            <input placeholder="Enter new version" className="popup__input-sel" />
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.59 8.29688L12 12.8769L7.41 8.29688L6 9.70687L12 15.7069L18 9.70687L16.59 8.29688Z" fill="#CBD5E1" />
                                            </svg>
                                        </div>

                                    </Grid>
                                    <Grid item className="popup__inputText padding24">
                                        <Typography className="popup__inputText">Variation<span style={{ color: "red" }}>*</span></Typography>
                                    </Grid>
                                    <Grid item className="padding24">
                                        <div className="popup__input">
                                            <input placeholder="Enter new variation" className="popup__input-sel" />
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.59 8.29688L12 12.8769L7.41 8.29688L6 9.70687L12 15.7069L18 9.70687L16.59 8.29688Z" fill="#CBD5E1" />
                                            </svg>
                                        </div>
                                    </Grid>
                                    <Grid item className="footerPopup">
                                        <button className="footerPopup__button">
                                            Map
                                        </button>
                                    </Grid>
                                </Grid>
                            </div>}
                        </Hidden>
                    </Categories>
                    <LevelBlock>

                        {this.state.dummyLevelData.length > 0 && this.renderLevel(this.state.dummyLevelData, 0)}
                        {this.state.dummyDa && this.renderUpid(this.state.dummyData)}
                    </LevelBlock>
                </Box>
            </SideBar>

        </>
        );
    }
}